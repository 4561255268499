<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Sprint Commitment</h3>
        <router-link class="mt-2" to="/crud/sprint-commitments/new"><span class="text-post">CREATE</span> Sprint Commitment
        </router-link>
    </div>
    <b-skeleton-table v-if="isLoading && !pagination.results"
                      animation="wave"
                      :rows="20"
                      :columns="5"
                      :table-props="{ striped: true }"
    ></b-skeleton-table>

    <b-overlay :show="!pagination.results || isLoading" class="table-responsive" id="entities"
                   v-if="pagination && pagination.results">
      <table class="table table-striped" aria-describedby="page-heading">
        <thead>
        <tr>
          <th class="text-capitalize">
            <div class="d-flex align-items-center">
              <div>
                Id
              </div>
              <div class="ml-2 d-flex flex-column">
                <span @click="onPaginationChange({sort: 'id,asc'})" :class="(pagination.sort === 'id,asc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-up"></i></span>
                <span @click="onPaginationChange({sort: 'id,desc'})" :class="(pagination.sort === 'id,desc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-down"></i></span>
              </div>
            </div>
          </th>
          <th class="text-capitalize">
            <div class="d-flex align-items-center">
              <div>
                User Id
              </div>
            </div>
          </th>

        <th class="text-capitalize">
          <div class="d-flex align-items-center">
            <div>
              Sprint Id
            </div>
          </div>
        </th>

        <th class="text-capitalize">
          <div class="d-flex align-items-center">
            <div>
              Committed delivery workload
            </div>
          </div>
        </th>

        <th class="text-capitalize">
          <div class="d-flex align-items-center">
            <div>
              Committed build up workload	
            </div>
          </div>
        </th>

        <th class="text-capitalize">
          <div class="d-flex align-items-center">
            <div>
              Created At
            </div>
            <div class="ml-2 d-flex flex-column">
              <span @click="onPaginationChange({sort: 'created_at,asc'})" :class="(pagination.sort === 'created_at,asc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-up"></i></span>
              <span @click="onPaginationChange({sort: 'created_at,desc'})" :class="(pagination.sort === 'created_at,desc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-down"></i></span>
            </div>
          </div>
        </th>

        <th class="text-capitalize">
          <div class="d-flex align-items-center">
            <div>
              Updated At
            </div>
            <div class="ml-2 d-flex flex-column">
              <span @click="onPaginationChange({sort: 'updated_at,asc'})" :class="(pagination.sort === 'updated_at,asc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-up"></i></span>
              <span @click="onPaginationChange({sort: 'updated_at,desc'})" :class="(pagination.sort === 'updated_at,desc') ? 'text-postman': 'text-dark'"><i class="fas fa-angle-down"></i></span>
            </div>
          </div>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="item in this.pagination.results" :key="item.id">
        <td>
          <router-link class="mt-2" :to="`/crud/sprint-commitments/${item.id}/edit`">
            {{ item.id }}
          </router-link>
        </td>
        <td>{{ item.user_id }}</td>
        <td>{{ item.sprint_id }}</td>
        <td>{{ item.committed_delivery_workload }}</td>
        <td>{{ item.committed_build_up_workload }}</td>

        <td>{{ item.created_at }}</td>
        <td>{{ item.updated_at }}</td>
        <td @click="deleteSprintCommit(item.id)" class="btn-danger">Delete</td>

      </tr>
      </tbody>
    </table>
  </b-overlay>
    <Pagination @paginationChange="onPaginationChange" :size="pagination.size" :page="pagination.page"
                    :total="pagination.total"></Pagination>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import Pagination from '../components/Pagination.vue';

export default {
  name: 'SprintCommitmentIndex',

  components: {
    Pagination
  },

  data() {
    return {
      isLoading: false,
      paginationReset: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: 'created_at,desc'
      },
      pagination: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: 'created_at,desc'
      },
    }
  },

  methods: {
    ...mapGetters(['request']),

    onPaginationChange(pagination){
      if (null != pagination.page){
        this.pagination.page = pagination.page;
      }
      if (null!= pagination.size){
        this.pagination.size = pagination.size;
      }
      if (null!= pagination.sort){
        this.pagination.sort = pagination.sort;
      }
      this.refreshTableData();
    },

    refreshTableData() {
      this.isLoading = true;
      window.scrollTo(0,0)
      const url = `api/v4/sprint-commitments`;
      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
      };

      this.request().get(url, {
        params
      }).then(res => {
        this.pagination = {...this.pagination, ...res.data};
      }).finally(() => {
        this.isLoading = false;
      })
    },

    deleteSprintCommit(id) {
      if (confirm("Are you sure?")) {
        this.isLoading = true;
        const url = `api/v4/sprint-commitments/`+ id;
        this.request().delete(url).finally(() => {
          this.isLoading = false;
          this.refreshTableData();
        })
      }
    }
  },

  mounted() {
    this.refreshTableData();
  }
}
</script>