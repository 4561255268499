<template>
  <div class="col-lg-6 col-10 mx-auto text-center">
    <b-form  v-on:submit.prevent="resetPassword()">
      <label>Enter your email:</label>
      <b-form-input v-model="email" type="email" placeholder="Email"></b-form-input>
      <button type="submit" class="btn btn-primary m-2">Submit</button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import router from "../../router";

export default {
  name: 'ForgotPassword',

  data() {
    return {
      auth: getAuth(),
      email: '',
    }
  },

  methods: {
    resetPassword() {
      sendPasswordResetEmail(this.auth, this.email)
        .then(() => {
          alert("An reset password email has been sent");
          router.push('/signin_iam')
        })
        .catch(error => {
          console.error(error.message)
          alert("Can't sent reset password email: " + error.message);
        })
    }
  }
}
</script>