import Vue from 'vue';
import { BootstrapVue, IconsPlugin,  } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'

import VueRouter from 'vue-router'
import App from './App';
import router from './router'
import store from './store/index';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter);


new Vue({
    render: (createElement) => createElement(App),
    router,
    store
}).$mount('#app');
