import { mapGetters } from "vuex";
import router from "../router";
import html2canvas from "html2canvas";

export default {
  name: "SprintRetros",

  data() {
    return {
      user: localStorage.getItem("username"),
      data: [],
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshTableData() {
      const url = `api/v4/sprint-retros`;
      const params = {
        page: 0,
        size: 20,
        sort: "created_at,desc",
      };

      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          const activeId = res.data.results[0].sprint_id;
          this.data = res.data.results.filter(
            (result) => result.sprint_id == activeId
          );
        });
    },

    editSprintRetros(id) {
      router.push("/crud/sprint-retrospectives/" + id + "/edit");
    },
    capture() {
      const captureElement = document.querySelector("#capture");
      html2canvas(captureElement)
        .then((canvas) => {
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas) => {
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", "my-image.png");
          a.setAttribute("href", image);
          a.click();
          canvas.remove();
        });
    },
  },

  mounted() {
    this.refreshTableData();
  },
};
