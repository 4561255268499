import {mapGetters} from 'vuex';

export default {
    components: {
    },

    data () {
        return {
            isLoading: false,
            version: '0.2.0'
        }
    },
    methods: {
        ...mapGetters(['request']),
    },

    async mounted() {
        const url = `version`;
        const params = {

        };

        this.isLoading = true;
        await this.request().get(url, {
            params,
            headers: {
                Authorization: null
            }
        }).then(res => {
            this.version = res.data;
        }).finally(() => {
            this.isLoading = false;
        });

    }
}
