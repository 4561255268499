<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-min-h-screen tw-bg-cyan-50">
    <!-- Card Container -->
    <div
      class="tw-relative tw-flex tw-flex-col tw-m-6 tw-space-y-10 tw-bg-white tw-shadow-2xl tw-rounded-2xl md:tw-flex-row md:tw-space-y-0 md:tw-m-0">
      <!-- Left side -->
      <div class="tw-p-6 md:tw-p-20">
        <!-- Top Content -->
        <h2 class="tw-font-mono tw-mb-5 tw-text-4xl tw-font-bold">Sign In</h2>
        <p class="tw-max-w-sm tw-mb-12 tw-font-sans tw-font-light tw-text-gray-600">
          Login to your account for the best COM API journey
        </p>
        <b-form v-on:submit.prevent="loginWithEmailAndPassword()">
          <label for="email-input" class="tw-font-sans tw-font-light tw-text-gray-600">Email:</label>
          <b-form-input name="email" id="email-input" v-model="form.email" type="email" class="tw-w-full tw-p-6 tw-border tw-border-gray-300 tw-rounded-md 
            tw-my-2 tw-py-4
            placeholder:tw-font-sans placeholder:tw-font-light
            " placeholder="Enter your email address" required>
          </b-form-input>
          <label for="password-input" class="tw-font-sans tw-font-light tw-text-gray-600">Password:</label>
          <b-form-input name="password-input" id="password-input" v-model="form.password" type="password" class="tw-w-full tw-p-6 tw-border tw-border-gray-300 tw-rounded-md 
              tw-my-2 tw-py-4
            placeholder:tw-font-sans placeholder:tw-font-light" placeholder="Enter your password" required>
          </b-form-input>
          <!-- Middle Content -->
          <div
            class="tw-flex tw-flex-col tw-items-center tw-justify-between tw-mt-6 tw-space-y-6 md:tw-flex-row md:tw-space-y-0">
            <router-link class="tw-flex-1" to="/forgot-password">
              <div class="tw-font-thin tw-text-cyan-700">Forgot password</div>
            </router-link>
            <b-button type="submit" variant="primary"
              class="tw-flex-1 tw-w-full md:tw-width-auto tw-flex tw-justify-center tw-items-center tw-p-6 tw-space-x-4 tw-font-sans tw-font-bold tw-text-white tw-rounded-md tw-shadow-lg tw-px-9 tw-bg-cyan-700 tw-shadow-cyan-100 hover:tw-bg-opacity-90 hover:tw-shadow-lg tw-border tw-transition hover:tw--translate-y-0.5 tw-duration-150">
              <div class="tw-flex tw-flex-row tw-justify-between tw-my-2 tw-px-4">
                <span>Sign in</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-6 tw-h-6" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7">
                  </path>
                </svg>
              </div>
            </b-button>
          </div>
        </b-form>

        <!-- Border -->
        <div class="tw-mt-12 tw-border-b tw-border-b-gray-300">
        </div>
        <!-- Bottom Content -->
        <p class="tw-py-6 tw-text-sm tw-font-thin tw-text-center tw-text-gray-400">or log in with</p>

        <!-- Bottom Button Container -->
        <div
          class="tw-flex tw-flex-col tw-justify-center tw-space-x-0 tw-space-y-6 md:tw-flex-row md:tw-space-x-4 md:tw-space-y-0">
          <button @click="loginGoogle()"
            class="tw-flex tw-items-center tw-justify-center tw-py-2 tw-space-x-3 tw-border tw-border-gray-300 tw-rounded hover:tw-shadow-lg hover:tw--translate-y-0.5 tw-transition tw-duration-150 md:tw-w-1/2">
            <img src="images/google.png" class="tw-w-9">
            <span class="tw-font-thin">Google</span>
          </button>
        </div>

      </div>
      <!-- Right side -->
      <img src="https://i.imgur.com/yTSTWel.jpg" alt="COM API Team"
        class="tw-w-[430px] tw-object-scale-down tw-object-center tw-hidden md:tw-block tw-pr-10" />

    </div>

  </div>
</template>


<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

export default {
  components: {},

  data() {
    return {
      provider: new GoogleAuthProvider(),
      auth: getAuth(),
      environment: "PROD",
      form: {
        email: '',
        password: ''
      },
    };
  },

  methods: {
    ...mapActions(["login", "logout", "finalizeLogin"]),
    ...mapGetters([
      "request",
      "baseUrl",
      "googleRedirectUrl",
      "googleGetIdTokenUrl",
    ]),

    loginWithEmailAndPassword() {
      signInWithEmailAndPassword(this.auth, this.form.email, this.form.password)
        .then((userCredential) => {
          // Signed in 
          console.log(userCredential)
          const user = userCredential.user;
          console.log(user)
          localStorage.setItem('access_token', user.accessToken);
          localStorage.setItem("refresh_token", user.refreshToken);
          this.login();
          this.request()
            .get('/api/v4/users/loginFirebase')
            .then(async (res) => {
              if (res.data == "") {
                alert("This user is undefine");
                await this.logout();
              } else {
                localStorage.setItem("username", res.data.email.split("@")[0]);
                this.finalizeLogin()
              }
            });
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.error(errorMessage)
          alert("Can't login: " + errorMessage);
        });
    },

    loginGoogle() {
      // window.location.href = this.googleRedirectUrl()(this.environment);
      signInWithPopup(this.auth, this.provider)
        .then((result) => {
          console.log(result)
          // The signed-in user info.
          const user = result.user;
          console.log(user);
          localStorage.setItem('access_token', user.accessToken);
          this.login();
          this.request()
            .get('/api/v4/users/loginFirebase')
            .then(async (res) => {
              if (res.data == "") {
                alert("This user is undefine");
                await this.logout();
              } else {
                localStorage.setItem("username", res.data.email.split("@")[0]);
                this.finalizeLogin()
              }
            });
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.warn(errorCode, errorMessage, email, credential)
          alert("Can't login with google: " + errorMessage);
        });
    },

    getGoogleAccount(code) {
      var id_token;
      const url = this.googleGetIdTokenUrl()(code, this.environment);
      this.request()
        .post(url)
        .then((res) => {
          id_token = res.data.id_token;
          localStorage.setItem("access_token", res.data.id_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);
        })
        .finally(() => {
          this.loginGoogleUser(id_token);
        });
    },

    async loginGoogleUser(id_token) {
      await this.login();
      const getUserURL = "/api/v4/users/loginGoogle";
      this.request()
        .post(getUserURL, {
          token: id_token,
        })
        .then(async (res) => {
          if (res.data == "") {
            alert("This user is undefine");
            await this.logout();
          } else {
            localStorage.setItem("username", res.data.email.split("@")[0]);
            this.finalizeLogin()
          }
        });
    },
  },

  mounted() {
    if (localStorage.getItem("access_token")) {
      this.finalizeLogin();
      return;
    }
    const code = this.$route.query.code;
    if (code != null) {
      this.getGoogleAccount(code);
    }
  },
};
</script>
