<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Create Sprint</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div>
            <div class="form-group">
              <label for="name" class="form-control-label">Sprint name</label>
              <input
                type="text"
                class="form-control"
                name="name"
                id="name"
                placeholder="name"
                v-model="name"
                required
              />
            </div>
            <label for="start_date">Start date</label>
            <b-form-datepicker
              name="start_date"
              id="start_date"
              v-model="start_date"
            >
            </b-form-datepicker>

            <label for="end_date">End date</label>
            <b-form-datepicker name="end_date" id="end_date" v-model="end_date">
            </b-form-datepicker>

            <div class="form-group">
              <label for="description" class="form-control-label"
                >description</label
              >
              <input
                type="text"
                class="form-control"
                name="description"
                id="description"
                placeholder="description"
                v-model="description"
              />
            </div>

            <div class="form-group">
              <label for="sprint_number" class="form-control-label"
                >sprint_number</label
              >
              <input
                required
                type="text"
                class="form-control"
                name="sprint_number"
                id="sprint_number"
                placeholder="sprint_number"
                v-model="sprint_number"
              />
            </div>

            <div class="form-group">
              <label for="status" class="form-control-label">Status</label>
              <b-form-select
                v-model="status"
                :options="['DRAFT', 'ACTIVE', 'CLOSED']"
                class="mb-3"
              >
              </b-form-select>
            </div>
          </div>

          <div>
            <button type="submit" id="save" class="btn-success">Save</button>
            <button class="btn-danger" @click="$router.go(-1)">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      activePrint: null,
      name: null,
      start_date: null,
      end_date: new Date(),
      description: null,
      sprint_number: null,
      status: "ACTIVE",
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      const url = `api/v4/sprints`;
      this.request()
        .post(url, {
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          description: this.description,
          sprint_number: this.sprint_number,
          status: this.status,
        })
        .then(async () => {
          await this.changeActiveSprint();         
        })
        .finally(() => { router.push("/crud/sprints");});
    },
    getActiveSprint() {
      const url2 = `api/v4/sprints/active`;
      this.request()
        .get(url2)
        .then((res) => {
          this.$activePrint = res.data;
        });
    },
    changeActiveSprint() {
      if (this.status == "ACTIVE") {
        this.$activePrint.status = "CLOSED";
        const url = `api/v4/sprints/${this.$activePrint.id}`;

        this.request().put(url, this.$activePrint);
      }
    },
  },

  mounted() {
    this.getActiveSprint();
    const today = new Date();
    this.start_date = new Date();
    this.end_date = new Date();
    if (today.getDay() >= 5) {
      this.start_date.setDate(today.getDate() - today.getDay() + 8);
      this.end_date.setDate(this.start_date.getDate() + 4);
    } else {
      this.start_date.setDate(today.getDate() - today.getDay() + 1);
      this.end_date.setDate(this.start_date.getDate() + 4);
    }
  },
};
</script>
