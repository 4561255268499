<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Edit Sprint Retrospective</h3>
    </div>

    <form v-on:submit.prevent="onSubmit">
      <div class="col-sm-2">
        <div class="form-group">
          <label for="assignee" class="form-control-label"
            >Member name<span class="text-danger">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            name="assignee"
            id="assignee"
            placeholder="Member name"
            v-model="assignee"
          />
        </div>
      </div>
      <div class="col-sm-2">
        <div class="form-group">
          <label for="sprint_id" class="form-control-label"
            >Sprint Id<span class="text-danger">*</span></label
          >
          <input
            required
            type="text"
            class="form-control"
            name="sprint_id"
            id="sprint_id"
            placeholder="Sprint Id"
            v-model="sprint_id"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="process" class="form-control-label">Process</label>
            <textarea
              type="text"
              class="form-control"
              name="process"
              id="process"
              placeholder="Process"
              rows="10"
              v-model="process"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="tools" class="form-control-label">Tools</label>
            <textarea
              type="text"
              class="form-control"
              name="tools"
              id="tools"
              placeholder="Tools"
              rows="10"
              v-model="tools"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="people" class="form-control-label">People</label>
            <textarea
              type="text"
              class="form-control"
              name="people"
              id="people"
              placeholder="People"
              rows="10"
              v-model="people"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="relationship" class="form-control-label"
              >Relationship</label
            >
            <textarea
              type="text"
              class="form-control"
              name="relationship"
              id="relationship"
              placeholder="Relationship"
              rows="10"
              v-model="relationship"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="other" class="form-control-label">Other</label>
            <textarea
              type="text"
              class="form-control"
              name="other"
              id="other"
              placeholder="Other"
              rows="10"
              v-model="other"
            />
          </div>
        </div>
      </div>
      <button type="submit" id="save" class="btn-success">
        Edit Spint Retrospective
      </button>
    </form>
    <button
      class="btn-danger my-2"
      @click="$router.push('/sprint-retrospectives')"
    >
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import router from "../router";
export default {
  name: "SprintRetrosEdit",

  data() {
    return {
      id: null,
      assignee: null,
      sprint_id: null,
      process: null,
      tools: null,
      people: null,
      relationship: null,
      other: null,
      date: null,
      created_at: null,
      updated_at: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshData() {
      const url = `api/v4/sprint-retros/${this.$route.params.id}`;

      this.request()
        .get(url)
        .then((res) => {
          (this.id = res.data.id),
            (this.assignee = res.data.assignee),
            (this.sprint_id = res.data.sprint_id),
            (this.process = res.data.process),
            (this.tools = res.data.tools),
            (this.people = res.data.people),
            (this.relationship = res.data.relationship),
            (this.other = res.data.other),
            (this.date = res.data.date),
            (this.created_at = res.data.created_at),
            (this.updated_at = res.data.updated_at);
        });
    },

    onSubmit() {
      const url = `api/v4/sprint-retros/${this.id}`;
      this.request()
        .put(url, {
          id: this.id,
          assignee: this.assignee,
          sprint_id: this.sprint_id,
          process: this.process,
          tools: this.tools,
          people: this.people,
          relationship: this.relationship,
          other: this.other,
          date: this.date,
          created_at: this.created_at,
          updated_at: this.updated_at,
        })
        .then(() => {
          router.push("/sprint-retrospectives");
        });
    },
  },

  mounted() {
    this.refreshData();
  },
};
</script>
