import { mapGetters } from "vuex";
import mixpanel from "mixpanel-browser";

export default {
  computed: {
    ...mapGetters(["isLoggedIn"]),
    name() {
      return localStorage.getItem("username") || "";
    },
  },
  data() {
    return {
      checkNav: false,
      userName: "",
    };
  },
  methods: {
    changeNav() {
      this.checkNav = !this.checkNav;
      if (this.checkNav) {
        document.getElementById("myTopnav").className += " responsive";
      } else {
        document.getElementById("myTopnav").classList.remove("responsive");
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },

  watch: {
    $route(to, from) {
      mixpanel.init("738921b9d5dd5cdb70f34d0f73986085", {
        debug: true,
        ignore_dnt: true,
      });
      mixpanel.track(this.userName, {
        "to": to.name,
        "from": from.name
      });
      
    },
  },
  mounted() {
    this.userName =  localStorage.getItem("username");
  },
};
