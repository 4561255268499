import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import SprintBacklogChild from "./SprintBacklogChild.vue";

export default {
  name: "SprintBacklogIndex",
  components: {
    SprintBacklogChild,
  },

  data() {
    return {
      //Create sprint goal
      form: {
        priority: null,
        summary: null,
        description: null,
        msc: "MUST",
        progress_percentage: 0,
      },
      msc: ["MUST", "SHOULD", "COULD"],
      show: false,

      //config
      isCreatingNew: false,
      isFilter: false,
      filter: {
        name: '',
        status: null,
        type: null,
        string: null,
        sprintGoal: null,
      },
      hide: true,
      statusOptions: [
        { value: null, text: "" },
        "TODO",
        "TO_TEST",
        "IN_PROGRESS",
        "DONE",
      ],
      typeOptions: [{ value: null, text: "" }, "TASK", "BUG"],

      //calculate
      sprintBacklogItems: [],
      sprintBacklogItemsTemp: [],
      goals: [],
      total_estimation: 0,
      total_spent_time: 0,
      total_test_estimation: 0,

      //users
      users: [],
      usersWithNull: [],

      //Sprints
      sprints: null,

      //New Backlog
      sprint_goal_id: null,
      assignee: localStorage.getItem("username"),
      backlog_item_type: "TASK",
      summary: null,
      description: null,
      priority: null,
      estimation_in_hours: 1,
      status: "TODO",
      test_estimation_in_hours: 0,
      test_assignee: null,

      //Move
      goals_move: null,
      sprint_id_move: null,

      //Goals
      fields: [
        "msc",
        "summary",
        "priority",
        {
          key: "progress_percentage",
          label: "Progress",
        },
        "action",
      ],
      sprint_id: null,
      sprint: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    async getActiveSprint() {
      //users
      const user_url = `api/v4/users`;
      await this.request()
        .get(user_url)
        .then((res) => {
          this.usersWithNull = [{ value: null, text: "NONE" }];
          res.data.forEach((e) => {
            this.users.push(e.email.split("@")[0]);
            this.usersWithNull.push(e.email.split("@")[0]);
          });
        });

      //sprint
      const urlSelect = `api/v4/sprints?page=0&size=5`;
      this.request()
        .get(urlSelect)
        .then((res) => {
          this.sprints = res.data.results;
        });

      //sprint active
      const url = `api/v4/sprints/active`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprint_id = res.data.id;
          this.sprint_id_move = res.data.id;
          this.sprint = res.data;
          this.refreshTableData();
        });
    },

    filterBySprintGoal(item) {
      if (item == "none") {
        this.filter.sprintGoal = item;
      } else {
        this.filter.sprintGoal = item.id;
      }
      this.backlogFilter();
    },

    refreshTableData() {
      //backlog
      const url = `/api/v4/sprints/${this.sprint_id}/backlog-items`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprintBacklogItems = this.sprintBacklogItemsTemp = res.data;
          this.backlogFilter();
        });

      //sprint-goals-active
      const urlActiveGoals = `api/v4/sprints/${this.sprint_id_move}/sprint-goals`;
      this.request()
        .get(urlActiveGoals)
        .then((res) => {
          this.goals_move = res.data.sort(this.compare);
        });

      //sprint-goals
      const urlGoals = `api/v4/sprints/${this.sprint_id}/sprint-goals`;
      this.request()
        .get(urlGoals)
        .then((res) => {
          this.goals = res.data.sort(this.compare);
        });
    },

    backlogFilter() {
      this.sprintBacklogItems = this.sprintBacklogItemsTemp;

      if (this.filter.status != null) {
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return e.status == this.filter.status;
        });
      }

      if (this.filter.type != null) {
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return e.backlog_item_type == this.filter.type;
        });
      }

      if (this.filter.name) {
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return e.assignee == this.filter.name || e.test_assignee == this.filter.name;
        });
      }

      if (this.filter.sprintGoal == "none") {
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return e.sprint_goal_id == null;
        });
      } else if (this.filter.sprintGoal != null) {
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return e.sprint_goal_id == this.filter.sprintGoal;
        });
      }

      if (this.filter.string && this.filter.string.trim()) {
        const filterString = this.filter.string.toLowerCase().trim();
        this.sprintBacklogItems = this.sprintBacklogItems.filter((e) => {
          return (
            (e.summary && e.summary.toLowerCase().indexOf(filterString) > -1) ||
            (e.description &&
              e.description.toLowerCase().indexOf(filterString) > -1)
          );
        });
      }

      this.getTime(this.sprintBacklogItems);
    },

    compare(a, b) {
      if (a.priority < b.priority) {
        return -1;
      }
      if (a.priority > b.priority) {
        return 1;
      }
      return 0;
    },

    getTime(data) {
      this.total_estimation = 0;
      this.total_spent_time = 0;
      this.total_test_estimation = 0;

      data.forEach((backlog) => {
        if (backlog.estimation_in_hours != null) {
          this.total_estimation += backlog.estimation_in_hours;
        }
        if (backlog.spent_time_in_hours != null) {
          this.total_spent_time += backlog.spent_time_in_hours;
        }
        if (backlog.test_estimation_in_hours != null) {
          this.total_test_estimation += backlog.test_estimation_in_hours;
        }
      });
    },

    onSubmit() {
      this.isCreatingNew = true;
      const url = `api/v4/backlog-items`;

      this.request()
        .post(url, {
          sprint_id: this.sprint_id,
          summary: this.summary,
          assignee: this.assignee,
          backlog_item_type: this.backlog_item_type,
          description: this.description,
          estimation_in_hours: this.estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: this.status,
          test_estimation_in_hours: this.test_estimation_in_hours,
          test_assignee: this.test_assignee,
        })
        .then(async () => {
          this.sprint_goal_id = null;
          this.summary = null;
          this.description = null;
          this.priority = null;
          this.estimation_in_hours = 1;
          this.test_estimation_in_hours = 0;
          await this.refreshTableData();
          this.hide = !this.hide;
          this.isCreatingNew = false;
        });
    },

    changeSprint() {
      this.sprint_id = event.target.value ? event.target.value : this.sprint_id;
      const url = `api/v4/sprints/${this.sprint_id}`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprint = res.data;
        });
      this.clearFilter();
    },

    async clearFilter() {
      this.filter.name = '';
      this.filter.type = null;
      this.filter.string = null;
      this.filter.status = null;
      this.filter.sprintGoal = null;
      this.sprintBacklogItems = this.sprintBacklogItemsTemp;
      await this.refreshTableData();
    },

    onSubmitCreateSprintGoal() {
      this.isCreatingNew = true;
      const url = `api/v4/sprint-goals`;

      this.request()
        .post(url, {
          sprint_id: this.sprint_id,
          msc: this.form.msc,
          priority: this.form.priority,
          summary: this.form.summary,
          description: this.form.description,
          progress_percentage: this.form.progress_percentage,
        })
        .then(() => {
          this.show = false;
          this.refreshTableData();
          this.onReset();
        })
        .finally(() => [(this.isCreatingNew = false)]);
    },

    onSubmitUpdateSprintGoal(item) {
      const index = item.index;
      const url = `api/v4/sprint-goals/${item.item.id}`;
      this.request()
        .put(url, {
          id: item.item.id,
          sprint_id: item.item.sprint_id,
          msc: item.item.msc,
          priority: item.item.priority,
          summary: item.item.summary,
          description: item.item.description,
          progress_percentage: item.item.progress_percentage,
        })
        .then(() => {
          const { detailsRow } = this;
          if (detailsRow && detailsRow !== this.goals[index]) {
            detailsRow._showDetails = false;
          }
          this.$set(
            this.goals[index],
            "_showDetails",
            !this.goals[index]._showDetails
          );
          this.detailsRow = this.goals[index];
        });
    },

    deleteSprintGoal(id) {
      if (confirm("Are you sure?")) {
        const url = `api/v4/sprint-goals/${id}`;
        this.request()
          .delete(url)
          .then(() => {
            this.refreshTableData();
          });
      }
    },

    onReset() {
      // Reset our form values
      this.form.priority = null;
      this.form.summary = null;
      this.form.description = null;
      this.form.msc = "MUST";
      this.form.progress_percentage = 0;
      // Trick to reset/clear native browser form validation state
      this.show = false;
    },
    capture() {
      const captureElement = document.querySelector("#capture");
      html2canvas(captureElement)
        .then((canvas) => {
          canvas.style.display = "none";
          document.body.appendChild(canvas);
          return canvas;
        })
        .then((canvas) => {
          const image = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
          const a = document.createElement("a");
          a.setAttribute("download", "my-image.png");
          a.setAttribute("href", image);
          a.click();
          canvas.remove();
        });
    },
  },

  async mounted() {
    await this.getActiveSprint();
  },
};
