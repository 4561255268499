<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>New TeamDate</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div>
            <div class="form-group">
              <label for="summary" class="form-control-label"
                >Summary<span class="text-danger">*</span></label
              >
              <textarea
                rows="4"
                cols="50"
                type="text"
                class="form-control"
                name="summary"
                id="summary"
                placeholder="summary"
                v-model="summary"
              />
            </div>

            <div class="form-group">
              <b-form-datepicker
                v-model="date"
                class="mb-2"
              ></b-form-datepicker>
            </div>

            <div class="form-group">
              <label for="link" class="form-control-label">Link</label>
              <input
                type="text"
                class="form-control"
                name="link"
                id="link"
                placeholder="link"
                v-model="link"
              />
            </div>
          

          <div class="form-group">
              <label for="description" class="form-control-label">Description</label>
              <textarea
                rows="4"
                cols="50"
                type="text"
                class="form-control"
                name="description"
                id="description"
                placeholder="description"
                v-model="description"
              />
            </div>
          </div>

          <div>
            <button
              :disabled="!isActive"
              type="submit"
              id="save"
              class="btn-success"
            >
              Save
            </button>
          </div>
        </form>
        <button class="btn-danger" @click="$router.push('/crud/teamdate')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      isActive: true,
      summary: null,
      date: null,
      link: null,
      description: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      this.isActive = false;
      const url = `api/v4/teamdate`;

      this.request()
        .post(url, {
          summary: this.summary,
          date: this.date,
          link: this.link,
          description: this.description
        })
        .finally(() => {
          router.push("/crud/teamdate");
        });
    },
  },
  mounted() {
    this.date = new Date();
  },
};
</script>
