import { mapGetters } from "vuex";

export default {
  name: "MyContribution",
  components: {},

  computed: {
    color() {
      if (this.item.backlog_item.status == "TODO") {
        return "clearfix p-2 bg-info";
      } else if (this.item.backlog_item.status == "DONE") {
        return "clearfix p-2 bg-success";
      } else if (this.item.backlog_item.status == "IN_PROGRESS") {
        return "clearfix p-2 bg-primary";
      } else {
        return "clearfix p-2 bg-secondary";
      }
    },
  },
  props: {
    item: null,
  },
  emits: {},
  data() {
    return {
      isSave: false,
      diff: false,
      isEdit: false,
      isHidden: true,
      users: [],
      //New
      id: this.item.backlog_item.id,
      assignee: this.item.backlog_item.assignee,
      test_assignee: this.item.backlog_item.test_assignee,
      test_estimation_in_hours: this.item.backlog_item.test_estimation_in_hours,
      spent_time_in_hours: 0.5,
      myDate: new Date(),
      descriptionWorklog: null,
      //Edit
      sprint_id: this.item.backlog_item.sprint_id,
      sprint_goal_id: this.item.backlog_item.sprint_goal_id,
      backlog_item_type: this.item.backlog_item.backlog_item_type,
      estimation_in_hours: this.item.backlog_item.estimation_in_hours,
      priority: this.item.backlog_item.priority,
      status: this.item.backlog_item.status,
      summary: this.item.backlog_item.summary,
      descriptionBacklog: this.item.backlog_item.description,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    async delete() {
      this.$alert("delete is called, update should not");
    },

    refreshSpentTime() {
      this.$emit("refreshSpentTime");
    },

    refreshWorklog() {
      this.$emit("refreshWorklog");
    },
    submitForm() {
      const url = `api/v4/worklogs`;
      this.request()
        .post(url, {
          backlog_item_id: this.id,
          assignee: localStorage.getItem("username"),
          spent_time_in_hours: this.spent_time_in_hours,
          date: this.myDate,
          description: this.descriptionWorklog,
        })
        .then(() => {
          this.diff = true;
          this.isHidden = true;
          this.$emit("refreshSpentTime").then(this.refreshWorklog());
        })
        .finally(() => {
          setTimeout(() => {
            this.diff = false;
          }, 500);
        });
    },
    submitFormEdit() {
      const url = `api/v4/backlog-items/${this.id}`;
      this.request()
        .put(url, {
          id: this.id,
          sprint_id: this.sprint_id,
          summary: this.summary,
          assignee: this.assignee,
          test_assignee: this.test_assignee,
          test_estimation_in_hours: this.test_estimation_in_hours,
          backlog_item_type: this.backlog_item_type,
          description: this.descriptionBacklog,
          estimation_in_hours: this.estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: this.status,
        })
        .then(() => {
          this.isEdit = false;
          this.$emit("refreshSpentTime").then(this.refreshWorklog());
        });
    },

    getUsers() {
      const url = `api/v4/users`
      this.request().get(url).then(res => {
        this.users = res.data.map(user => {
          return user.email.split('@')[0]
        })
      })
    }
  },

  mounted() {
    this.myDate.setDate(this.myDate.getDate() - 1)
    this.getUsers();
  },
};
