<template>
  <div>
    <TopNavBar v-if="loggedIn"></TopNavBar>
    <AppNotifications></AppNotifications>

    <section>
      <div class="container-fluid animateFromRight">
        <div class="row pt-md-4 mt-3 mb-5">
          <div class="col-12 mx-auto">
            <div class="row align-items-center">
              <div class="col-12 mb-4 mb-xl-0 main-container">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AppNotifications from "./components/AppNotifications";
import TopNavBar from "./components/TopNavBar";
import {initializeApp} from "firebase/app";
import {mapGetters, mapState} from "vuex";

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG_JSON)

// Initialize Firebase
initializeApp(firebaseConfig);
export default {
  name: "App",
  components: {
    AppNotifications,
    TopNavBar,
  },
  data() {
    return {
      loggedIn: false,
    }
  },
  computed: {...mapState(['token']), ...mapGetters(['isLoggedIn'])},
  created() {
    this.loggedIn = this.isLoggedIn
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setToken') {
        this.loggedIn = !!state.auth.token;
        console.log(this.loggedIn)
      }
    })
  },

  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style src="./App.css"></style>
