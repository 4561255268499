const NOTIFICATION_FLASH_TIME = 5000;
const state = {
  errorNotifications: [],
  warningNotifications: [],
  infoNotifications: [],
  successNotifications: [],
};

const getters = {
  errorNotifications: (state) => {
    return state.errorNotifications;
  },
  warningNotifications: (state) => {
    return state.warningNotifications;
  },
  infoNotifications: (state) => {
    return state.infoNotifications;
  }, 
  successNotifications: (state) => {
    return state.successNotifications;
  },

};

const actions = {
  toastError: ({commit}, message) => {
    commit('addError', message);
    setTimeout(() => {
      commit('removeError', message);
    }, NOTIFICATION_FLASH_TIME);
  },
  toastWarning: ({commit}, message) => {
    commit('addWarning', message);
    setTimeout(() => {
      commit('removeWarning', message);
    }, NOTIFICATION_FLASH_TIME);
  },
  toastInfo: ({commit}, message) => {
    commit('addInfo', message);
    setTimeout(() => {
      commit('removeInfo', message);
    }, NOTIFICATION_FLASH_TIME);
  },
  toastSuccess: ({commit}, message) => {
    commit('addSuccess', message);
    setTimeout(() => {
      commit('removeSuccess', message);
    }, NOTIFICATION_FLASH_TIME);
  }
}

const mutations = {
  addError: (state, message) => {
    state.errorNotifications.push(message);
  },
  removeError: (state, message) => {
    state.errorNotifications = state.errorNotifications.filter(item => item !== message);
  },
  addWarning: (state, message) => {
    state.warningNotifications.push(message);
  },
  removeWarning: (state, message) => {
    state.warningNotifications = state.warningNotifications.filter(item => item !== message);
  },
  addInfo: (state, message) => {
    state.infoNotifications.push(message);
  },
  removeInfo: (state, message) => {
    state.infoNotifications = state.infoNotifications.filter(item => item !== message);
  },
  addSuccess: (state, message) => {
    state.successNotifications.push(message);
  },
  removeSuccess: (state, message) => {
    state.successNotifications = state.successNotifications.filter(item => item !== message);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}
