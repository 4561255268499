import { mapGetters } from "vuex";
import router from '../router';

export default {
  name: "SprintBacklogChild",
  components: {},

  props: {
    item: null,
    users: null,
  },

  data() {
    return {
      isEdit: false,
      isHidden: true,
      isMove: true,
      goals_priority: null,
      usersWithNull: null,

      //New
      id: this.item.id,
      assignee: this.item.assignee,
      spent_time_in_hours: 0.5,
      myDate: new Date().toISOString().slice(0, 10),
      descriptionWorklog: null,

      //Edit
      sprint_id: this.item.sprint_id,
      sprint_goal_id: this.item.sprint_goal_id,
      backlog_item_type: this.item.backlog_item_type,
      estimation_in_hours: this.item.estimation_in_hours
        ? this.item.estimation_in_hours
        : 0,
      test_assignee: this.item.test_assignee,
      test_estimation_in_hours: this.item.test_estimation_in_hours
        ? this.item.test_estimation_in_hours
        : 0,
      priority: this.item.priority,
      status: this.item.status,
      summary: this.item.summary,
      descriptionBacklog: this.item.description,
      
      //Move
      sprint_id_move: null,
      sprint_goal_id_move: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),
    getActiveSprint() {
      const url = `api/v4/sprints/active`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprint_id_move = res.data.id;
        });
      if (this.sprint_goal_id != null) {
        this.setGoalsPriority(this.sprint_goal_id);
      }
    },

    async delete() {
      this.$alert("delete is called, update should not");
    },

    createWorklog() {
      const url = `api/v4/worklogs`;
      this.request()
        .post(url, {
          backlog_item_id: this.id,
          assignee: localStorage.getItem("username"),
          spent_time_in_hours: this.spent_time_in_hours,
          date: this.myDate,
          description: this.descriptionWorklog,
        })
        .then(() => {
          this.$parent.refreshTableData();
          this.isHidden = !this.isHidden;
        });
    },

    editBacklog() {
      this.isDisabled = !this.isDisabled;
      const url = `api/v4/backlog-items/${this.id}`;
      this.request()
        .put(url, {
          id: this.id,
          sprint_id: this.sprint_id,
          summary: this.summary,
          assignee: this.assignee,
          backlog_item_type: this.backlog_item_type,
          description: this.descriptionBacklog,
          estimation_in_hours: this.estimation_in_hours,
          test_assignee: this.test_assignee,
          test_estimation_in_hours: this.test_estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: this.status,
        })
        .then(() => {
          if (this.sprint_goal_id != null) {
            this.setGoalsPriority(this.sprint_goal_id);
          }
          this.$parent.refreshTableData();
          this.isEdit = !this.isEdit;
        });
    },

    setGoalsPriority(id) {
      const urls = `api/v4/sprint-goals/${id}`;
      this.request()
        .get(urls)
        .then((res) => {
          this.goals_priority = res.data.priority;
        });
    },

    moveToActiveSprint() {
      const url = `api/v4/backlog-items/${this.id}`;
      if (this.sprint_goal_id_move != -1) {
        this.sprint_goal_id = this.sprint_goal_id_move;
        this.setGoalsPriority(this.sprint_goal_id);
      } else {
        this.sprint_goal_id = null;
        this.goals_priority = null;
      }
      this.request()
        .put(url, {
          id: this.id,
          sprint_id: this.sprint_id_move,
          summary: this.summary,
          assignee: this.assignee,
          backlog_item_type: this.backlog_item_type,
          description: this.descriptionBacklog,
          estimation_in_hours: this.estimation_in_hours,
          test_assignee: this.test_assignee,
          test_estimation_in_hours: this.test_estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: this.status,
        })
        .then(() => {
          this.$parent.refreshTableData();
          this.isMove = !this.isMove;
        });
    },

    deleteBackLogItem(id) {
      if (confirm("Are you sure?")) {
        const url = `api/v4/backlog-items/` + id;
        this.request()
          .delete(url)
          .finally(() => {
            this.$parent.refreshTableData();
          });
      }
    },

    doneBackLogItem() {
      const url = `api/v4/backlog-items/${this.id}`;
      this.request()
        .put(url, {
          id: this.id,
          sprint_id: this.sprint_id,
          summary: this.summary,
          assignee: this.assignee,
          backlog_item_type: this.backlog_item_type,
          description: this.descriptionBacklog,
          estimation_in_hours: this.estimation_in_hours,
          test_assignee: this.test_assignee,
          test_estimation_in_hours: this.test_estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: "DONE",
        })
        .then(() => {
          if (this.sprint_goal_id != null) {
            this.setGoalsPriority(this.sprint_goal_id);
          }
          this.status = "DONE"
        })
        .finally(() => {
          this.$parent.refreshTableData();
        });
    },
    backlogDetail(id){
        let route = router.resolve({path: '/backlog-items/'+id});
        window.open(route.href, '_blank');
    },
  },

  async mounted() {
    await this.getActiveSprint();
    this.usersWithNull = [{value: null, text: "NONE"}]
    this.users.forEach(user => {
      this.usersWithNull.push(user)
    })
  },
};
