import { mapGetters } from "vuex";
export default {
  name: "MemberAccountability",

  data() {
    return {
      user: localStorage.getItem("username"),
      isTable: true,
      isLoading: false,
      sprint: null,
      data: [],
      today: null,
      week: null,
      fields: ["assignee", "committed_delivery_workload", "committed_build_up_workload", "total_estimation"]
    };
  },

  methods: {
    ...mapGetters(["request"]),

    async getActiveSprint() {
      const url = `api/v4/sprints/active`;
      await this.request()
        .get(url)
        .then((res) => {
          this.sprint = res.data;
        });
    },

    isToday(key) {
      const day = new Date(key);
      if (
        day.getDate() == this.today.getDate() &&
        day.getMonth() == this.today.getMonth()
      ) {
        return true;
      }
      return false;
    },

    sortObject: function(o) {
      return Object.keys(o)
        .sort()
        .reduce((r, k) => ((r[k] = o[k]), r), {});
    },

    async refreshData() {
      this.isLoading = true;
      const url = `api/v4/member-accountability?sprint-id=${this.sprint.id}`;

      await this.request()
        .get(url)
        .then(async (res) => {
          this.data = res.data.filter(data => {
            return data.total_estimation > 0
          });
          for (const data of this.data) {
            if (data.committed_delivery_workload == null) {
              data["_cellVariants"] = {total_estimation: "danger"}
            } else if (data.total_estimation > data.committed_delivery_workload + data.committed_build_up_workload) {
              data["_cellVariants"] = {total_estimation: "warning"}
            }
            let days = this.sortObject(data.week)
            for (const day in days) {
              const date = new Date(day).toString().slice(0, 10)
              data[date] = data.week[day]
              if (!this.fields.includes(day)){
                if (this.isToday(day)) {
                  this.fields.push({key: date, variant: "info"})
                } else {
                  this.fields.push(date)
                }
              }
            }
          }
          this.fields.push("total_work_log_time")
        });
      this.isLoading = false;
    },
  },

  mounted() {
    this.getActiveSprint().then(() => {
      this.refreshData();
    });

    this.today = new Date();
  },
};
