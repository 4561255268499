import Pagination from "../components/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  name: "SprintRetrosIndex",
  components: {
    Pagination,
  },

  data() {
    return {
      isLoading: false,
      paginationReset: {
        total: 0,
        results: null,
        size: 5,
        page: 0,
        sort: "created_at,desc",
      },
      pagination: {
        total: 0,
        size: 5,
        results: null,
        page: 0,
        sort: "created_at,desc",
      },
    };
  },

  methods: {
    ...mapGetters(["request"]),
    onPaginationChange(pagination) {
      window.scroll(0,0)
      if (null != pagination.page) {
        this.pagination.page = pagination.page;
      }
      if (null != pagination.size) {
        this.pagination.size = pagination.size;
      }
      if (null != pagination.sort) {
        this.pagination.sort = pagination.sort;
      }
      this.refreshTableData();
    },

    refreshTableData() {
      this.isLoading = true;
      const url = `api/v4/sprint-retros`;
      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
      };

      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          this.pagination = { ...this.pagination, ...res.data };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    deleteSprintRetros(id) {
      if (confirm("Are you sure?")) {
        this.isLoading = true;
        const url = `api/v4/sprint-retros/${id}`;
        this.request()
          .delete(url)
          .finally(() => {
            this.refreshTableData();
          });
      }
    },
  },

  mounted() {
    this.refreshTableData();
  },
};
