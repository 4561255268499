import VueRouter from "vue-router";
import SignInIam from "../views/authenticate/SignInIam.vue";
import RedirectIam from "../views/authenticate/RedirectIam.vue";
import SignOut from "../views/authenticate/SignOut.vue";
import Signup from "../views/authenticate/Signup"
import LinkAccount from "../views/authenticate/LinkAccount"
import ForgotPassword from "../views/authenticate/ForgotPassword";
import Version from "../views/Version.vue";
import NotFound from "../views/NotFound.vue";
import BacklogItemIndex from "../views/BacklogItemIndex";
import SprintIndex from "../views/SprintIndex";
import SprintGoalIndex from "../views/SprintGoalIndex";
import BacklogItemNew from "../views/BacklogItemNew";
import BacklogItemEdit from "../views/BacklogItemEdit";
import MeetingMinuteNew from "../views/MeetingMinuteNew";
import MeetingMinuteIndex from "../views/MeetingMinuteIndex";
import MeetingMinuteEdit from "../views/MeetingMinuteEdit";
import MeetingMinute from "../views/MeetingMinute";
import MyContributionIndex from "../views/MyContributionIndex";
import Worklog from "../views/Worklog";
import WorklogEdit from "../views/WorklogEdit";
import SprintCommitmentIndex from "../views/SprintCommitmentIndex";
import SprintCommitmentEdit from "../views/SprintCommitmentEdit";
import SprintCommitmentNew from "../views/SprintCommitmentNew";
import WorklogNew from "../views/WorklogNew";
import SprintGoalEdit from "../views/SprintGoalEdit";
import SprintGoalNew from "../views/SprintGoalNew";
import SprintRetrosIndex from "../views/SprintRetrosIndex";
import SprintRetros from "../views/SprintRetros";
import SprintRetrosEdit from "../views/SprintRetrosEdit";
import SprintRetrosNew from "../views/SprintRetrosNew";
import WorklogIndex from "../views/WorklogIndex";
import TeamDateIndex from "../views/TeamDateIndex";
import TeamDate from "../views/TeamDate";
import TeamDateNew from "../views/TeamDateNew";
import TeamDateEdit from "../views/TeamDateEdit";
import SprintBacklogIndex from "../views/SprintBacklogIndex";
import ProductBacklog from "../views/ProductBacklog";
import SprintNew from "../views/SprintNew";
import SprintEdit from "../views/SprintEdit";
import DeliveryTimeline from "../views/DeliveryTimeline";
import ServerVersion from "../views/ServerVersion";
import BacklogItemDetail from "../views/BacklogItemDetail";
import RecurringPayment from "../views/RecurringPayment";

const routes = [{
        path: "/crud/backlog-items",
        name: "BacklogItemIndex",
        component: BacklogItemIndex,
    },
    {
        path: "/crud/worklogs",
        name: "WorklogIndex",
        component: WorklogIndex,
    },
    {
        path: "/crud/teamdate",
        name: "TeamDateIndex",
        component: TeamDateIndex,
    },
    {
        path: "/crud/teamdate/new",
        name: "TeamDateNew",
        component: TeamDateNew,
    },
    {
        path: "/crud/teamdate/:id/edit",
        name: "TeamDateEdit",
        component: TeamDateEdit,
    },
    {
        path: "/crud/worklogs/new",
        name: "WorklogNew",
        component: WorklogNew,
    },
    {
        path: "/crud/sprints",
        name: "SprintIndex",
        component: SprintIndex,
    },
    {
        path: "/crud/sprint-goals",
        name: "SprintGoalIndex",
        component: SprintGoalIndex,
    },
    {
        path: "/crud/sprint-goals/new",
        name: "SprintGoalNew",
        component: SprintGoalNew,
    },

    {
        path: "/crud/sprint-goals/:id/edit",
        name: "SprintGoalEdit",
        component: SprintGoalEdit,
    },
    {
        path: "/crud/backlog-items/new",
        name: "BacklogItemNew",
        component: BacklogItemNew,
    },

    {
        path: "/crud/backlog-items/:id/edit",
        name: "BacklogItemEdit",
        component: BacklogItemEdit,
    },
    {
        path: "/crud/meeting-minutes/new",
        name: "MeetingMinuteNewCRUD",
        component: MeetingMinuteNew,
    },
    {
        path: "/crud/meeting-minutes",
        name: "MeetingMinuteIndex",
        component: MeetingMinuteIndex,
    },
    {
        path: "/crud/meeting-minutes/:id/edit",
        name: "MeetingMinuteEdit",
        component: MeetingMinuteEdit,
    },
    {
        path: "/crud/sprint-commitments",
        name: "SprintCommitmentIndex",
        component: SprintCommitmentIndex,
    },
    {
        path: "/crud/sprint-commitments/:id/edit",
        name: "SprintCommitmentEdit",
        component: SprintCommitmentEdit,
    },
    {
        path: "/crud/sprint-commitments/new",
        name: "SprintCommitmentNew",
        component: SprintCommitmentNew,
    },
    {
        path: "/crud/worklogs/:id/edit",
        name: "WorklogEdit",
        component: WorklogEdit,
    },
    {
        path: "/crud/sprint-retrospectives",
        name: "SprintRetrosIndex",
        component: SprintRetrosIndex,
    },
    {
        path: "/crud/sprint-retrospectives/:id/edit",
        name: "SprintRetrosEdit",
        component: SprintRetrosEdit,
    },
    {
        path: "/crud/sprint-retrospectives/new",
        name: "SprintRetrosNewCRUD",
        component: SprintRetrosNew,
    },
    {
        path: "/",
        name: "HomePage",
        component: SignInIam,
    },
    {
        path: "/signin_iam",
        name: "SignInIam",
        component: SignInIam,
    },
    {
        path: "/redirect-iam",
        name: "RedirectIam",
        component: RedirectIam,
    },
    {
        path: "/sign-out",
        name: "SignOut",
        component: SignOut,
    },
    {
        path: "/version",
        name: "Version",
        component: Version,
    },
    {
        path: "*",
        name: "NotFound",
        component: NotFound,
    },
    {
        path: "/my-contributions",
        name: "MyContributionIndex",
        component: MyContributionIndex,
    },
    {
        path: "/sprint-backlogs",
        name: "SprintBacklogIndex",
        component: SprintBacklogIndex,
    },
    {
        path: "/crud/sprints/new",
        name: "SprintNew",
        component: SprintNew,
    },
    {
        path: "/crud/sprints/:id/edit",
        name: "SprintEdit",
        component: SprintEdit,
    },

    {
        path: "/meeting-minutes",
        name: "MeetingMinute",
        component: MeetingMinute,
    },
    {
        path: "/sprint-retrospectives/new",
        name: "SprintRetrosNew",
        component: SprintRetrosNew,
    },
    {
        path: "/sprint-retrospectives",
        name: "SprintRetros",
        component: SprintRetros,
    },
    {
        path: "/teamdate",
        name: "TeamDate",
        component: TeamDate,
    },
    {
        path: "/meeting-minutes/new/form",
        name: "MeetingMinuteNew",
        component: MeetingMinuteNew,
    },
    {
        path: "/meeting-minutes/new",
        name: "Worklog",
        component: Worklog,
    },
    {
        path: "/product-backlog",
        name: "ProductBacklog",
        component: ProductBacklog,
    },
    {
        path: "/delivery-timeline",
        name: "DeliveryTimeline",
        component: DeliveryTimeline,
    },
    {
        path: "/server-version",
        name: "ServerVersion",
        component: ServerVersion,
    },
    {
        path: "/recurring-payment",
        name: "RecurringPayment",
        component: RecurringPayment,
    },
    {
        path: "/backlog-items/:id",
        name: "BacklogItemDetail",
        component: BacklogItemDetail,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/link-account",
        name: "LinkAccount",
        component: LinkAccount,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    }
];

const router = new VueRouter({
    routes,
    mode: "history",
});

router.beforeEach((to, from, next) => {
    if (to.fullPath.startsWith("/webhook-operations/")) {
        const isAccessTokenAvailable = localStorage.getItem("access_token");
        const exp = localStorage.getItem("exp") || null;

        const isTokenActive = exp != null && exp > `${Date.now() / 1000}`;

        if (!isAccessTokenAvailable || !isTokenActive) {
            //this.authService.logout();
            //this.warningNotificationsService.pushMessage(`Session time-out, please login again!`);
            router.push("/signin_iam");
        }
    }
    if (to.fullPath.startsWith("/server-version")) {
        const isAccessTokenAvailable = localStorage.getItem("access_token");
        if (!isAccessTokenAvailable) {
            router.push("/signin_iam");
        }
    }

    next();
});

export default router;