<template>
    <div class="fixed-top row d-flex justify-content-end" style="transition: all 1s; pointer-events: none;">
        <div class="col-4 mt-1 mr-5">
            <div v-for="error of errorNotifications" :key="error">
                <div class="alert alert-danger alert-dismissible fade show rounded-0" role="alert">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            style="pointer-events: auto">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <div v-for="success of successNotifications" :key="success">
                <div class="alert alert-success alert-dismissible fade show rounded-0" role="alert">
                    {{success}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            style="pointer-events: auto">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <div v-for="warning of warningNotifications" :key="warning">
                <div class="alert alert-warning alert-dismissible fade show rounded-0" role="alert">
                    {{warning}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            style="pointer-events: auto">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>

            <div v-for="info of infoNotifications" :key="info">
                <div class="alert alert-info alert-dismissible fade show rounded-0" role="alert">
                    {{info}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"
                            style="pointer-events: auto">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'AppNotifications',
        computed: {
            ...mapGetters(['errorNotifications', 'warningNotifications', 'infoNotifications', 'successNotifications']),
        },
    }
</script>
