import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(["isLoggedIn"]),
    name() {
      return localStorage.getItem("access_token") || "";
    },
  },
  data() {
    return {
      iframeUrl: "", // Replace with your desired URL
      snazzyToken: ""
    };
  },
  mounted() {
    this.snazzyToken =  localStorage.getItem("access_token");
    this.iframeUrl = `https://quiet-bonbon-41890c.netlify.app?snazzy-token=${localStorage.getItem("access_token")}`;
    console.log("looq " + this.iframeUrl);
  },
};