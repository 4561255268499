<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>New Worklog</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div class="form-group">
            <label for="backlog_item_id" class="form-control-label"
              >Backlog Item Id <span class="text-danger">*</span></label
            >
            <input
              required
              type="text"
              class="form-control"
              name="backlog_item_id"
              id="backlog_item_id"
              placeholder="backlog_item_id"
              v-model="backlog_item_id"
            />
          </div>

          <div class="form-group">
            <label for="assignee" class="form-control-label"
              >Assignee<span class="text-danger">*</span></label
            >
            <input
              required
              type="text"
              class="form-control"
              name="assignee"
              id="assignee"
              placeholder="assignee"
              v-model="assignee"
            />
          </div>

          <div class="form-group">
            <label for="spent_time_in_hours" class="form-control-label"
              >Spent Time in Hours <span class="text-danger">*</span></label
            >
            <b-form-spinbutton
              v-model="spent_time_in_hours"
              min="0.5"
              max="6"
              step="0.5"
            ></b-form-spinbutton>
          </div>

          <div class="form-group">
            <label for="date" class="form-control-label">Date</label>
            <b-form-datepicker v-model="date" class="mb-2"></b-form-datepicker>
          </div>

          <div class="form-group">
            <label for="description" class="form-control-label"
              >description</label
            >
            <input
              type="text"
              class="form-control"
              name="description"
              id="description"
              placeholder="description"
              v-model="description"
            />
          </div>
          <div>
            <button
              :disabled="isActive"
              type="submit"
              id="save"
              class="btn-success"
            >
              Save
            </button>
          </div>
        </form>
        <button class="btn-danger" @click="$router.push('/crud/worklogs')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      isActive: false,
      backlog_item_id: this.$route.query.backlog_id
        ? this.$route.query.backlog_id
        : null,
      assignee: localStorage.getItem("username"),
      spent_time_in_hours: 0.5,
      date: null,
      description: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      this.isActive = true;

      const url = `api/v4/worklogs`;

      this.request()
        .post(url, {
          backlog_item_id: this.backlog_item_id,
          assignee: this.assignee,
          spent_time_in_hours: this.spent_time_in_hours,
          date: this.date,
          description: this.description,
        })
        .finally(() => {
          router.push("/crud/worklogs");
        });
    },
  },

  mounted() {
    this.date = new Date();
  },
};
</script>
