<template>
    <div>
        <nav class="justify-content-between d-flex mt-3">

            <ul class="pagination justify-content-center">


                <li v-if="page>0" class="page-item">
                    <button @click="navigateTo(previousPage, size)" class="page-link py-2 px-3 rounded-0 text-get">
                        <span>&laquo; Previous</span>
                    </button>
                </li>
                <!-- TODO vutk, fix limit this page render for memory -->
                <!-- <form class="form-inline mx-2">
                    <select class="custom-select d-inline rounded-0"
                            @change="navigateTo($event.target.value, size)">
                        <option v-for="(item, index) of [].constructor(totalPages)" :key="index" :value="index"
                                :selected="page===index">Page {{index}}
                        </option>
                    </select>

                </form> -->

                <li v-if="page < totalPages - 1" class="page-item">
                    <button @click="navigateTo(nextPage, size)" class="page-link py-2 px-3 rounded-0 text-get">
                        <span>Next &raquo;</span>
                    </button>
                </li>

            </ul>


            <div class="text-center mb-2 d-flex justify-content-center align-items-center ">
                <span class="mr-3">Showing {{firstItemIndex}} -&gt; {{lastItemIndex}} of {{total}}</span>
                <span v-if="isShowingPerPage">Per page</span>
                <form class="form-inline ml-2" v-if="isShowingPerPage">

                    <select class="custom-select d-inline rounded-0" @change="navigateTo(0, $event.target.value)">
                        <option v-for="i in [5, 10, 20, 50]" :key="i"
                                :selected="size===i">{{i}}
                        </option>
                    </select>
                </form>
            </div>

        </nav>

    </div>
</template>

<script>
    export default {
        name: 'Pagination',
        props: {
            isShowingPerPage: {
                type: Boolean,
                default: true
            },
            total: {
                type: Number,
                default: 0
            },
            size: {
                type: Number,
                default: 10
            },
            page: {
                type: Number,
                default: 0
            },
            currentLocation: {
                type: String,
                default: ''
            }
        },
        data(){
            return {
            }
        },
        methods: {
            navigateTo(page, size) {
                const pagination = {
                    page: Number.parseInt(page),
                    size: Number.parseInt(size)
                };
                this.$emit('paginationChange', pagination)
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.total / this.size);
            },

            nextPage() {
                return +this.page + +1;
            },

            previousPage() {
                return +this.page - +1;
            },

            firstItemIndex() {
                return this.page * this.size + 1;
            },

            lastItemIndex() {
                return (this.total < ((this.page + 1) * this.size)) ? this.total : ((this.page + 1) * this.size);
            }
        }

    }
</script>
