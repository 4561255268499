<template>
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-12">
      <table class="table table-condensed col-12 col-sm-12 col-md-12 col-lg-12">
        <thead>
          <tr>
            <th class="text-white" style="background-color: #40c267;">
              {{
                backlog_detail.assignee ? backlog_detail.assignee : "?Assignee"
              }}
            </th>
            <td>
              {{
                backlog_detail.priority
                  ? "Priority: " + backlog_detail.priority
                  : "Priority: null"
              }}
            </td>
            <td>
              {{
                backlog_detail.backlog_item_type
                  ? "Type: " + backlog_detail.backlog_item_type
                  : "Type: null"
              }}
            </td>
          </tr>
          <tr>
            <td style="background-color: #40c24f ;">
              Sprint {{ backlog_detail.sprint_id
              }}<span
                >- Goal
                {{
                  backlog_detail.sprint_goal_id
                    ? backlog_detail.sprint_goal_id
                    : " null"
                }}</span
              >- Backlog {{ backlog_detail.id }}
            </td>
            <td>
              {{
                backlog_detail.estimation_in_hours
                  ? "Estimation: " + backlog_detail.estimation_in_hours
                  : "Estimation: 0"
              }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {{
                backlog_detail.test_assignee
                  ? "Tester: " + backlog_detail.test_assignee
                  : "Tester: empty"
              }}
            </td>
            <td>
              {{
                backlog_detail.test_estimation_in_hours
                  ? "Test estimation: " +
                    backlog_detail.test_estimation_in_hours
                  : "Test estimation: 0"
              }}
            </td>
            <td class="bg-dark text-white">
              {{
                backlog_detail.status
                  ? "Status: " + backlog_detail.status
                  : "Status: null"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12">
      <ul class="list-group">
        <li class="list-group-item text-white" style="background-color: #333;">
          Summary:<br />
          <p class="overflow-auto">{{ backlog_detail.summary ? backlog_detail.summary : "empty" }}</p>
        </li>
        <li v-if="backlog_detail.description" class="list-group-item bg-secondary">
         <b>Description:</b> <br />

            <b class="overflow-auto">
              {{
              backlog_detail.description ? backlog_detail.description : "empty"
            }}
            </b>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BacklogItemDetail",
  components: {},
  data() {
    return {
      backlog_detail: 0,
    };
  },
  methods: {
    ...mapGetters(["request"]),

    refreshData() {
      this.isLoading = true;
      const url = `api/v4/backlog-items/${this.$route.params.id}`;

      this.request()
        .get(url)
        .then((res) => {
          this.backlog_detail = res.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>

<style scoped></style>
