import { mapGetters } from "vuex";
import router from "../router";
import Pagination from "../components/Pagination.vue";

export default {
  name: "MeetingMinuteIndex",
  components: {
    Pagination,
  },

  data() {
    return {
      isLoading: false,
      paginationReset: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: "created_at,desc",
      },
      pagination: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: "created_at,desc",
      },
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onPaginationChange(pagination) {
      window.scroll(0, 0);
      if (null != pagination.page) {
        this.pagination.page = pagination.page;
      }
      if (null != pagination.size) {
        this.pagination.size = pagination.size;
      }
      if (null != pagination.sort) {
        this.pagination.sort = pagination.sort;
      }
      this.refreshTableData();
    },

    refreshTableData() {
      this.isLoading = true;
      window.scrollTo(0, 0);
      const url = `api/v4/meeting-minutes`;
      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
      };

      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          this.pagination = { ...this.pagination, ...res.data };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    deleteMeetingMinute(id) {
      if (confirm("Are you sure?")) {
        this.isLoading = true;
        const url = `api/v4/meeting-minutes/` + id;
        this.request()
          .delete(url)
          .finally(() => {
            this.isLoading = false;
            this.refreshTableData();
          });
      }
    },

    editMeetingMinute(id) {
      router.push("/crud/meeting-minutes/" + id + "/edit");
    },
  },

  mounted() {
    this.refreshTableData();
  },
};
