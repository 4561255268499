<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Create Backlog Item</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div>
            <div class="form-group">
              <label for="sprint_id" class="form-control-label"
                >Sprint Id <span class="text-danger">*</span></label
              >
              <input
                required
                type="text"
                class="form-control"
                name="sprint_id"
                id="sprint_id"
                placeholder="sprint_id"
                v-model="sprint_id"
              />
            </div>

            <div class="form-group">
              <label for="sprint_goal_id" class="form-control-label"
                >Sprint Goal Id</label
              >
              <input
                type="text"
                class="form-control"
                name="sprint_goal_id"
                id="sprint_goal_id"
                placeholder="sprint_goal_id"
                v-model="sprint_goal_id"
              />
            </div>

            <div class="form-group">
              <label for="summary" class="form-control-label"
                >Summary <span class="text-danger">*</span></label
              >
              <textarea
                required
                type="text"
                rows="5"
                class="form-control"
                name="summary"
                id="summary"
                placeholder="summary"
                v-model="summary"
              />
            </div>

            <div class="form-group">
              <label for="description" class="form-control-label"
                >Description</label
              >
              <textarea
                type="text"
                rows="5"
                class="form-control"
                name="description"
                id="description"
                placeholder="description"
                v-model="description"
              />
            </div>

            <div class="form-group">
              <label for="priority" class="form-control-label">Priority</label>
              <input
                type="number"
                class="form-control"
                name="priority"
                id="priority"
                placeholder="priority"
                v-model="priority"
              />
            </div>

            <div class="form-group">
              <label for="estimation_in_hours" class="form-control-label"
                >Estimation (hours)</label
              >
              <b-form-spinbutton
                v-model="estimation_in_hours"
                min="0.5"
                max="8"
                step="0.5"
              ></b-form-spinbutton>
            </div>

            <div class="form-group">
              <label for="assignee" class="form-control-label"
                >Assignee <span class="text-danger">*</span></label
              >
              <input
                required
                type="text"
                class="form-control"
                name="assignee"
                id="assignee"
                placeholder="assignee"
                v-model="assignee"
              />
            </div>

            <div class="form-group">
              <label for="test_estimation_in_hours" class="form-control-label"
                >Test Estimation (hours)</label
              >
              <b-form-spinbutton
                v-model="test_estimation_in_hours"
                min="0.5"
                max="8"
                step="0.5"
              ></b-form-spinbutton>
            </div>

            <div class="form-group">
              <label for="test_assignee" class="form-control-label"
                >Test Assignee</label
              >
              <input
                type="text"
                class="form-control"
                name="test_assignee"
                id="test_assignee"
                placeholder="test_assignee"
                v-model="test_assignee"
              />
            </div>

            <div class="form-group">
              <label for="status" class="form-control-label">Status</label>
              <b-form-select
                v-model="status"
                :options="['TODO', 'IN_PROGRESS', 'TO_TEST', 'DONE']"
                class="mb-3"
              >
              </b-form-select>
            </div>
          </div>

          <div>
            <button
              :disabled="isActive"
              type="submit"
              id="save"
              class="btn-success"
            >
              Save
            </button>
          </div>
        </form>
        <button class="btn-danger" @click="$router.push('/crud/backlog-items')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      isActive: false,
      sprint_id: null,
      sprint_goal_id: null,
      assignee: localStorage.getItem("username"),
      test_assignee: null,
      backlog_item_type: "TASK",
      summary: null,
      description: null,
      priority: null,
      estimation_in_hours: 0.5,
      test_estimation_in_hours: 0,
      status: "TODO",
    };
  },

  methods: {
    ...mapGetters(["request"]),

    getActiveSprint() {
      const url = `api/v4/sprints/active`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprint_id = res.data.id;
        });
    },

    onSubmit() {
      this.isActive = true;
      const url = `api/v4/backlog-items`;

      this.request()
        .post(url, {
          sprint_id: this.sprint_id,
          summary: this.summary,
          assignee: this.assignee,
          test_assignee: this.test_assignee,
          backlog_item_type: this.backlog_item_type,
          description: this.description,
          estimation_in_hours: this.estimation_in_hours,
          test_estimation_in_hours: this.test_estimation_in_hours,
          priority: this.priority,
          sprint_goal_id: this.sprint_goal_id,
          status: this.status,
        })
        .then(() => {
          router.push("/crud/backlog-items");
        });
    },
  },

  mounted() {
    this.getActiveSprint();
  },
};
</script>
