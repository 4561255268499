import Search from "../components/Search.vue";
import Pagination from "../components/Pagination.vue";
import { mapGetters } from "vuex";
import router from "../router";

export default {
  name: "SprintIndex",
  components: {
    Search,
    Pagination,
  },

  data() {
    return {
      isLoading: false,
      searchParams: {},
      paginationReset: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: "created_at,desc",
      },
      pagination: {
        total: 0,
        results: null,
        size: 10,
        page: 0,
        sort: "created_at,desc",
      },
    };
  },

  methods: {
    ...mapGetters(["request"]),
    onSearchChange(searchParams) {
      this.searchParams = searchParams;
      this.pagination = this.paginationReset;
      this.refreshTableData();
    },
    onPaginationChange(pagination) {
      if (null != pagination.page) {
        this.pagination.page = pagination.page;
      }
      if (null != pagination.size) {
        this.pagination.size = pagination.size;
      }
      if (null != pagination.sort) {
        this.pagination.sort = pagination.sort;
      }
      this.refreshTableData();
    },

    refreshTableData() {
      this.isLoading = true;
      const url = `api/v4/sprints`;
      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
        ...this.searchParams,
      };

      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          this.pagination = { ...this.pagination, ...res.data };
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onDelete(id) {
      if (confirm("Do you want delete???")) {
        const url = "api/v4/sprints/" + id;
        this.request()
          .delete(url)
          .finally(() => {
            this.refreshTableData();
          });
      }
    },

    onEdit(id) {
      router.push("/crud/sprints/" + id + "/edit");
    },
  },

  mounted() {
    this.refreshTableData();
  },
};
