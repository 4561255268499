<template>
    <div>
        <form @submit.prevent="onSubmit">

            <div v-for="(f, i) of searchFields" :key="i">
                <div class="input-group mb-2">
                    <div class="input-group-addon mx-2" :style="i === 0 ? '':'visibility:hidden'">
                        <a class="btn rounded-0 btn-success" @click="addMoreSearchField"><i
                                class="fas fa-plus-circle text-white"></i></a>
                    </div>
                    <select class="custom-select" name="search_field" v-model="searchFields[i]">
                        <option value=''>Choose field...</option>
                        <option v-for="field of fields" :key="field" :value="field" :selected="searchFields[i]===field">{{field}}</option>
                    </select>

                    <input type="search" class="form-control search-input" placeholder="Enter search value..." name="search_value"
                           v-model="searchValues[i]">

                    <div class="input-group-addon ml-2" v-if="i>0">
                        <a class="btn rounded-0 btn-danger" @click="removeSearchField(i)"><i
                                class="fas fa-minus-circle text-white"></i></a>
                    </div>

                    <button type="submit" class="btn rounded-0 btn-light search-button ml-2" v-if="i===0"><i class="fas fa-search text-danger"></i></button>

                </div>
            </div>

        </form>

    </div>
</template>


<script>

    export default {
        name: 'Search',
        props: {
            fields: {
                type: Array,
                default: () => []
            },
            searchFields: {
                type: Array,
                default: () => ['']
            },
            searchValues: {
                type: Array,
                default: () => ['']
            }
        },
        methods: {
            onSubmit(){
                const searchParams = {}
                for (let i = 0; i < this.searchFields.length; i++) {
                    searchParams[this.searchFields[i]]=this.searchValues[i]
                }
                this.$emit('searchChange', searchParams);
            },
            addMoreSearchField(){
                this.searchFields.push('');
                this.searchValues.push('');
            },
            removeSearchField(i) {
                this.searchFields.splice(i, 1);
                this.searchValues.splice(i, 1);
            }
        }
    }
</script>
