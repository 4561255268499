
<template>
    <div
            class="d-flex justify-content-center align-items-center"
            style="height: 100vh"
    >
        <div class="spinner-border m-5" role="status">
            <span class="sr-only">Signing out...</span>
        </div>

    </div>

</template>

<script  lang="ts">
    import {mapActions} from 'vuex';
    import { getAuth, signOut } from "firebase/auth";

    export default {
        methods: {
            ...mapActions(['logout']),
        },

        created() {
            const auth = getAuth();
            signOut(auth).then(() => {
                this.logout();
            }).catch((error) => {
                console.log(error)
            });
        }
    }

</script>
