<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Edit Worklog</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div>
            <div class="form-group">
              <label for="backlog_item_summary" class="form-control-label"
                >Backlog Item Summary <span class="text-danger">*</span></label
              >
              <textarea
                required
                rows="5"
                type="text"
                class="form-control"
                name="backlog_item_summary"
                id="backlog_item_summary"
                placeholder="backlog_item_summary"
                v-model="backlog_item_summary"
              />
            </div>

            <div class="form-group">
              <label for="assignee" class="form-control-label"
                >Assignee</label
              >
              <input
                type="text"
                class="form-control"
                name="assignee"
                id="assignee"
                placeholder="assignee"
                v-model="assignee"
              />
            </div>

            <div class="form-group">
              <label for="spent_time_in_hours" class="form-control-label"
                >Spent Time in Hours <span class="text-danger">*</span></label
              >
              <b-form-spinbutton
                v-model="spent_time_in_hours"
                min="0.5"
                max="6"
                step="0.5"
              ></b-form-spinbutton>
            </div>

            <div class="form-group">
              <label for="description" class="form-control-label"
                >Description</label
              >
              <textarea
                rows="5"
                type="text"
                class="form-control"
                name="description"
                id="description"
                placeholder="description"
                v-model="description"
              />
            </div>
            <div class="form-group">
              <b-form-datepicker
                v-model="date"
                class="mb-2"
              ></b-form-datepicker>
            </div>
          </div>

          <div>
            <button type="submit" id="save" class="btn-success">
              Save
            </button>
          </div>
        </form>
        <button class="btn-danger" @click="$router.push('/crud/worklogs')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      id: this.$route.params.id,
      backlog_item_summary: null,
      assignee: null,
      spent_time_in_hours: null,
      date: null,
      description: null,
      created_at: null,
      updated_at: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      const url = `api/v4/worklogs/${this.$route.params.id}`;

      this.request()
        .put(url, {
          id: this.id,
          backlog_item_summary: this.backlog_item_summary,
          assignee: this.assignee,
          spent_time_in_hours: this.spent_time_in_hours,
          date: this.date,
          description: this.description,
          created_at: this.created_at,
          updated_at: this.updated_at,
        })
        .then(() => {
          router.push("/crud/worklogs");
        });
    },

    refreshData() {
      this.isLoading = true;
      const url = `api/v4/worklogs/${this.$route.params.id}`;

      this.request()
        .get(url)
        .then((res) => {
          this.id = res.data.id;
          this.backlog_item_summary = res.data.backlog_item_summary;
          this.assignee = res.data.assignee;
          this.spent_time_in_hours = res.data.spent_time_in_hours;
          this.date = res.data.date;
          this.description = res.data.description;
          this.created_at = res.data.created_at;
          this.updated_at = res.data.updated_at;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.refreshData();
  },
};
</script>
