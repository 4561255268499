 <template>
  <div>
    <b-button @click="show = true" variant="primary" class="btn-info" size="sm">
      <i class="fa-solid fa-angles-right"></i
    ></b-button>
    <b-modal v-model="show" title="Chose Sprint Goal">
      <b-container fluid>
        <b-row class="mb-1">
          <b-col cols="3">Sprint Goal</b-col>
          <b-col>
            <b-form-select
              v-model="selected"
              :options="goalList"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <p class="float-left">You can choose no sprint goal</p>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
          <b-button
            variant="success"
            size="sm"
            class="float-right"
            @click="moveToActiveSprint(item), (show = false)"
          >
            Move</b-button
          >
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script lang="ts">
import { mapGetters } from "vuex";
export default {
  name: "SprintBacklogModal",
  computed: {},
  props: {
    activeSprintId: Number,
    item: null,
    goalList: Object,
  },

  data() {
    return {
      selected: null,
      sprint_goal_id: null,
      show: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    moveToActiveSprint(item) {
      console.log(this.selected.value);
      const url = `/api/v4/backlog-items/${item.id}`;
      this.request()
        .put(url, {
          id: item.id,
          sprint_id: this.activeSprintId,
          summary: item.summary,
          assignee: item.assignee,
          backlog_item_type: item.backlog_item_type,
          description: item.description,
          estimation_in_hours: item.estimation_in_hours,
          priority: item.priority,
          sprint_goal_id: this.selected == -1 ? null : this.selected,
          status: item.status,
        })
        .then(() => {});
    },
  },

  mounted() {},
};
</script>