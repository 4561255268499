import { mapGetters } from "vuex";
import router from "../router";

export default {
  name: "TeamDateIndex",
  components: {},

  data() {
    return {
      sprint: null,
      results: [],
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshTableData() {
      window.scroll(0, 0);
      const url = `api/v4/teamdate`;
      const params = {
        sort: 'created_at,desc',
        size: 20
      }
      this.request()
        .get(url, {
          params
        })
        .then((res) => {
          this.results = res.data.results
        })
        .finally(() => {});
    },

    onDelete(id) {
      if (confirm("Do u want delete???")) {
        const url = "api/v4/teamdate/" + id;
        this.request()
          .delete(url)
          .finally(() => {
            this.refreshTableData();
          });
      }
    },

    onEdit(id) {
      router.push("/crud/teamdate/" + id + "/edit");
    },
  },

  mounted() {
    this.refreshTableData();
  },
};
