<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Create Meeting Minutes!</h3>
    </div>

    <form v-on:submit.prevent="onSubmit">
      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label for="last_work" class="form-control-label"
              >Last Work<span class="text-danger">*</span></label
            >
            <textarea
              required
              type="text"
              class="form-control"
              name="last_work"
              id="last_work"
              placeholder="Last work"
              rows="10"
              v-model="last_work"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="blocking_points" class="form-control-label"
              >Blocking Points</label
            >
            <textarea
              type="text"
              class="form-control"
              name="blocking_points"
              id="blocking_points"
              placeholder="Blocking Points"
              rows="10"
              v-model="blocking_points"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="next_work" class="form-control-label"
              >What's next?<span class="text-danger">*</span></label
            >
            <textarea
              required
              type="text"
              class="form-control"
              name="next_work"
              id="next_work"
              placeholder="Next Work"
              rows="10"
              v-model="next_work"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="notes" class="form-control-label"
              >Notes for Retro</label
            >
            <textarea
              type="text"
              class="form-control"
              name="notes"
              id="notes"
              placeholder="Notes"
              rows="10"
              v-model="notes"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <b-form-datepicker v-model="date" class="mb-2"></b-form-datepicker>
          </div>
        </div>
      </div>
      <button :disabled="isActive" type="submit" id="save" class="btn-success">
        Create Meeting Minute
      </button>
    </form>
    <button class="btn-danger my-2" @click="$router.push('/meeting-minutes')">
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  name: "MeetingMinuteNew",
  components: {},

  data() {
    return {
      isActive: false,
      member_name: localStorage.getItem("username"),
      last_work: null,
      blocking_points: null,
      next_work: null,
      notes: null,
      date: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      this.isActive = true;
      const url = "api/v4/meeting-minutes";
      this.request()
        .post(url, {
          member_name: this.member_name,
          last_work: this.last_work,
          blocking_points: this.blocking_points,
          next_work: this.next_work,
          notes: this.notes,
          date: this.date,
        })
        .then(() => {
          router.push("/meeting-minutes");
        });
    },
  },

  mounted() {
    window.scroll(0, 0);
    this.date = new Date();
  },
};
</script>
