<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3 id="MC">My Contributions</h3>
    </div>

    <div class="container-fluid" v-for="item in items" :key="item.id">
      <MyContribution
        @refreshWorklog="refreshWorklog"
        @refreshSpentTime="getActiveSprint"
        :item="item"
      />
      <br />
    </div>
  </div>
</template>
<script lang="ts">
import { mapGetters } from "vuex";
import MyContribution from "./MyContribution.vue";

export default {
  name: "MyContributionIndex",
  components: {
    MyContribution,
  },

  data() {
    return {
      id: null,
      items: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),
    getActiveSprint() {
      const url = `api/v4/sprints/active`;
      this.request()
        .get(url)
        .then((res) => {
          this.id = res.data.id;
          this.refreshTableData();
        });
    },

    refreshTableData() {
      const url = `api/v4/member-contributions?member=${localStorage.getItem(
        "username"
      )}&sprint-id=${this.id}`;
      this.request()
        .get(url)
        .then((res) => {
          this.items = res.data
            .filter((item) => {
              return item != null;
            })
            .sort(function (a, b) {
              return a.backlog_item.id - b.backlog_item.id;
            });

          console.log(this.items);
        });
    },
    refreshWorklog() {
      this.$emit("refreshWorklog");
    },
  },

  mounted() {
    this.getActiveSprint();
  },
};
</script>