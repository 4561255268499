<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Edit Meeting Minute</h3>
    </div>
    <form v-on:submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-sm-0">
          <label for="id" class="form-control-label">Id</label>
          <p>{{ id }}</p>
        </div>
        <div class="col-sm-1">
          <label for="member_name" class="form-control-label"
            >Member Name</label
          >
          <p>{{ member_name }}</p>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="last_work" class="form-control-label">Last Work</label>
            <textarea
              type="text"
              class="form-control form-control-sm"
              name="last_work"
              id="last_work"
              rows="10"
              v-model="last_work"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="blocking_points" class="form-control-label"
              >Blocking Points</label
            >
            <textarea
              type="text"
              class="form-control form-control-sm"
              name="blocking_points"
              id="blocking_points"
              rows="10"
              v-model="blocking_points"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="next_work" class="form-control-label"
              >What's next?</label
            >
            <textarea
              type="text"
              class="form-control form-control-sm"
              name="next_work"
              id="next_work"
              rows="10"
              v-model="next_work"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="notes" class="form-control-label"
              >Notes for Retro</label
            >
            <textarea
              type="text"
              class="form-control form-control-sm"
              name="notes"
              id="notes"
              rows="10"
              v-model="notes"
            />
          </div>
        </div>
        <div class="col-sm-1">
          <div class="form-group">
            <b-form-datepicker v-model="date" class="mb-2"></b-form-datepicker>
          </div>
        </div>
      </div>
      <button type="submit" id="save" class="btn-success">
        Save Meeting Minute
      </button>
    </form>
    <button class="btn-danger my-2" @click="$router.push('/meeting-minutes')">
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import router from "../router";
export default {
  name: "MeetingMinuteEdit",
  components: {},

  data() {
    return {
      id: this.$route.params.id,
      member_name: null,
      last_work: null,
      blocking_points: null,
      next_work: null,
      notes: null,
      date: null,
      created_at: null,
      updated_at: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshData() {
      const url = `api/v4/meeting-minutes/${this.$route.params.id}`;

      this.request()
        .get(url)
        .then((res) => {
          (this.id = res.data.id),
            (this.member_name = res.data.member_name),
            (this.last_work = res.data.last_work),
            (this.blocking_points = res.data.blocking_points),
            (this.next_work = res.data.next_work),
            (this.notes = res.data.notes),
            (this.date = res.data.date),
            (this.created_at = res.data.created_at),
            (this.updated_at = res.data.updated_at);
        });
    },

    onSubmit() {
      const url = `api/v4/meeting-minutes/` + this.id;
      this.request()
        .put(url, {
          id: this.id,
          member_name: this.member_name,
          last_work: this.last_work,
          blocking_points: this.blocking_points,
          next_work: this.next_work,
          notes: this.notes,
          date: this.date,
          created_at: this.created_at,
          updated_at: this.updated_at,
        })
        .then(() => {
          router.push("/meeting-minutes");
        });
    },
  },

  mounted() {
    this.refreshData();
  },
};
</script>
