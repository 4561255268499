import { mapGetters } from "vuex";
import router from "../router";
import MyContributionIndex from "./MyContributionIndex.vue";
import MeetingMinuteNew from "./MeetingMinuteNew.vue";

export default {
  name: "Worklogs",
  components: {
    MyContributionIndex,
    MeetingMinuteNew,
  },

  data() {
    return {
      results: [],
      isLoading: false,
      searchParams: {},
      paginationReset: {
        total: 0,
        results: null,
        size: 50,
        page: 0,
        sort: "created_at,desc",
      },
      pagination: {
        total: 0,
        results: null,
        size: 50,
        page: 0,
        sort: "created_at,desc",
      },
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshTableData() {
      var day = new Date();
      if (day.getDay() == 0) {
        day.setDate(day.getDate() - 1);
      }
      day.setDate(day.getDate() - day.getDay());

      this.isLoading = true;
      const url = `api/v4/worklogs`;
      const params = {
        page: this.pagination.page,
        size: this.pagination.size,
        sort: this.pagination.sort,
        ...this.searchParams,
      };

      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          this.results = res.data.results.filter(
            (value) => new Date(value.date) > day
          );
        });
    },
    refreshWorklog() {
      this.refreshTableData();
    },

    onDelete(id) {
      if (confirm("Do u want delete???")) {
        const url = "api/v4/worklogs/" + id;
        this.request()
          .delete(url)
          .finally(() => {
            this.refreshTableData();
          });
      }
    },

    onEdit(id) {
      router.push("/crud/worklogs/" + id + "/edit");
    },
  },

  mounted() {
    window.scroll(0, 0);
    this.refreshTableData();
  },
};
