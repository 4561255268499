<template>
  <div>
    <b-table fixed :items="items" :fields="fields">
      <template #cell(action)="row">
        <SprintBacklogModal
          :item="row.item"
          :activeSprintId="activeSprintId"
          :goalList="goalList"
        />
        <b-button class="btn-info" size="sm" @click="row.toggleDetails">
          <i class="fa-solid fa-pen-to-square"></i>
        </b-button>
      </template>
      <template #row-details="row">
        <b-form @submit="onSubmit(row)">
          <b-form-group label="Assignee" label-for="assignee">
            <b-form-input
              id="assignee"
              v-model="row.item.assignee"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Priority" label-for="priority">
            <b-form-input
              id="priority"
              v-model="row.item.priority"
              type="number"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Summary" label-for="summary">
            <b-form-textarea
              id="summary"
              v-model="row.item.summary"
              required
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Description" label-for="description">
            <b-form-textarea
              id="description"
              v-model="row.item.description"
            ></b-form-textarea>
          </b-form-group>
          <b-form-group label="Status" label-for="status">
            <b-form-select
              id="status"
              v-model="row.item.status"
              :options="status"
              required
            ></b-form-select>
          </b-form-group>
          <b-button type="submit" variant="success">Submit</b-button>
          <b-button @click="row.toggleDetails" variant="danger"
            >Cancel</b-button
          >
        </b-form>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import SprintBacklogModal from "./SprintBacklogModal.vue";
export default {
  name: "SprintBacklog",
  components: {
    SprintBacklogModal,
  },

  props: {
    items: [],
  },

  data() {
    return {
      activeSprintId: null,
      goalList: [],
      show: false,
      fields: [
        "id",
        "assignee",
        "test_assignee",
        "priority",
        "status",
        "summary",
        "description",
        "action",
      ],
      status: ["TODO", "IN_PROGRESS", "TO_TEST", "DONE"],
    };
  },

  methods: {
    ...mapGetters(["request"]),

    async getActiveSprint() {
      const url = `api/v4/sprints/active`;
      await this.request()
        .get(url)
        .then((res) => {
          this.activeSprintId = res.data.id;
          return res.data.id;
        });
    },
    async getSprintActiveGoal() {
      await this.getActiveSprint();
      const url = `api/v4/sprints/${this.activeSprintId}/sprint-goals`;
      this.request()
        .get(url)
        .then((res) => {
          this.goalList.push({ value: -1, text: "No Sprint Goal" });
          for (const item of res.data) {
            this.goalList.push({ value: item.id, text: item.summary });
          }
        });
    },

    onSubmit(row) {
      const index = row.index;
      event.preventDefault();
      const url = `/api/v4/backlog-items/${this.items[index].id}`;
      this.request()
        .put(url, {
          id: this.items[index].id,
          sprint_id: this.items[index].sprint_id,
          summary: this.items[index].summary,
          assignee: this.items[index].assignee,
          backlog_item_type: this.items[index].backlog_item_type,
          description: this.items[index].description,
          estimation_in_hours: this.items[index].estimation_in_hours,
          priority: this.items[index].priority,
          sprint_goal_id: this.items[index].sprint_goal_id,
          status: this.items[index].status,
        })
        .then(() => {
          const { detailsRow } = this;
          if (detailsRow && detailsRow !== this.items[index]) {
            detailsRow._showDetails = false;
          }
          this.$set(
            this.items[index],
            "_showDetails",
            !this.items[index]._showDetails
          );
          this.detailsRow = this.items[index];
        });
    },
  },

  async mounted() {
    await this.getSprintActiveGoal();
  },
};
</script>