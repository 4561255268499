<template>
  <div>
    <div class="col-lg-6 col-10 mx-auto text-center">
      <h2>
        Signup
      </h2>
    <b-form v-on:submit.prevent="onSignUp">
    <label>Email: </label>
      <b-form-input 
        id="email-input-signup"
        v-model="signup.email"
        type="email"
        placeholder="Enter email"
        required>
      </b-form-input>
    <label>Password: </label>
      <b-form-input 
        id="password-input-signup"
        v-model="signup.password"
        type="password"
        placeholder="Enter password"
        required>
      </b-form-input>
      <b-button class="m-2" type="submit" variant="primary">Signup</b-button>
    </b-form>
    </div>
  </div>
  
</template>

<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import router from "../../router";

export default {
  name: 'Signup',

  data() {
    return {
      auth: getAuth(),
      signup: {
        email: '',
        passwork: ''
      }
    }
  },

  methods: {
    ...mapGetters(["request"]),
    ...mapActions(["login"]),

    onSignUp() {
      createUserWithEmailAndPassword(this.auth, this.signup.email, this.signup.password)
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(user)
          localStorage.setItem('access_token', user.accessToken);
          this.login();
          const user_url = `api/v4/users/register`;
          await this.request()
            .post(user_url, {
              email: this.signup.email,
              password: this.signup.password
            })
            .then(() => {
              alert("Successful create an account")
              router.push("/")
            })
            .catch(() => {
              alert("Can't create user please try again")
            });
        })
        .catch((error) => {
          const errorMessage = error.message;
          console.error(errorMessage)
          alert("Create firebase user failed: " + errorMessage)
        });
    },
  },

  mounted() {
  }
  
}
</script>