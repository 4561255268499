<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Edit Sprint Commitments</h3>
    </div>
    <form v-on:submit.prevent="onSubmit">
      <div class="form-row">
        <div class="col-sm-1">
          <label class="label">Id</label>
          <p>{{ id }}</p>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="user_id" class="form-control-label">User Id</label>
            <input
              required
              type="number"
              class="form-control"
              name="user_id"
              id="user_id"
              placeholder="User Id"
              v-model="user_id"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="sprint_id" class="form-control-label">Sprint Id</label>
            <input
              required
              type="number"
              class="form-control"
              name="sprint_id"
              id="sprint_id"
              placeholder="Sprint Id"
              v-model="sprint_id"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="committed_delivery_workload" class="form-control-label"
              >Commitment Delivery Workload</label
            >
            <input
              type="number"
              step="0.5"
              class="form-control"
              name="committed_delivery_workload"
              id="committed_delivery_workload"
              placeholder="Delivery"
              v-model="committed_delivery_workload"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="committed_build_up_workload" class="form-control-label"
              >Commitment Build Up Workload</label
            >
            <input
              type="number"
              step="0.5"
              class="form-control"
              name="committed_build_up_workload"
              id="committed_build_up_workload"
              placeholder="Build Up"
              v-model="committed_build_up_workload"
            />
          </div>
        </div>
      </div>
      <button type="submit" id="save" class="btn-success">
        Save Sprint Commitment
      </button>
    </form>
    <button
      class="btn-danger my-2"
      @click="$router.push('/crud/sprint-commitments')"
    >
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import router from "../router";
export default {
  name: "SprintCommitmentEdit",

  data() {
    return {
      id: null,
      user_id: null,
      sprint_id: null,
      committed_delivery_workload: null,
      committed_build_up_workload: null,
      created_at: null,
      updated_at: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    refreshData() {
      const url = `api/v4/sprint-commitments/${this.$route.params.id}`;
      this.request()
        .get(url)
        .then((res) => {
          (this.id = res.data.id),
            (this.user_id = res.data.user_id),
            (this.sprint_id = res.data.sprint_id),
            (this.committed_delivery_workload =
              res.data.committed_delivery_workload),
            (this.committed_build_up_workload =
              res.data.committed_build_up_workload),
            (this.created_at = res.data.created_at),
            (this.updated_at = res.data.updated_at);
        });
    },

    onSubmit() {
      const url = `api/v4/sprint-commitments/` + this.id;
      this.request()
        .put(url, {
          id: this.id,
          user_id: this.user_id,
          sprint_id: this.sprint_id,
          committed_delivery_workload: this.committed_delivery_workload,
          committed_build_up_workload: this.committed_build_up_workload,
          created_at: this.created_at,
          updated_at: this.updated_at,
        })
        .then(() => {
          router.push("/crud/sprint-commitments");
        });
    },
  },

  mounted() {
    this.refreshData();
  },
};
</script>
