<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="spinner-border m-5" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapActions} from 'vuex';
import jwt_decode from "jwt-decode";
import router from "../../router";

export default {
  name: 'RedirectIAM',
  mounted:function() {
    console.log('Mounted...');
    this.redirect();
  },

  methods: {
    ...mapActions(['finalizeLogin']),
    redirect() {
      console.log(`Executing redirect`);
      const params = new URLSearchParams();
      params.append("client_id", localStorage.getItem("client_id") || "");
      params.append(
        "client_secret",
        atob(localStorage.getItem("client_secret")) || ""
      );
      params.append("code", this.$route.query.code);
      params.append("redirect_uri", localStorage.getItem("redirect_uri") || "");
      params.append("grant_type", "authorization_code");

      axios
        .post(
          `${localStorage.getItem(
            "keycloak_host"
          )}/protocol/openid-connect/token`,
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((tokenResponse) => {
          if (
            tokenResponse &&
            tokenResponse.data &&
            tokenResponse.data.access_token
          ) {
            localStorage.setItem(
              "access_token",
              `${tokenResponse.data.access_token}`
            );
            const accessTokenDecoded = jwt_decode(tokenResponse.data.access_token) || {};
            localStorage.setItem("exp", accessTokenDecoded.exp);
            localStorage.setItem('name', accessTokenDecoded.name || (accessTokenDecoded.preferred_username || accessTokenDecoded.email));
            this.finalizeLogin(`${tokenResponse.data.access_token}`);
          } else {
            //this.errorNotificationsService.pushMessage('Failed to login');
            console.error(`Failed to fetch token`);
            router.push("/signin_iam");
          }
        })
        .catch((err) => {
          console.error(err.message);
          //this.errorNotificationsService.pushMessage(`Failed to login ${err.message}`)
          router.push("/signin_iam");
        });
    },
  }
};
</script>
