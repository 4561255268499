var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between mb-4 mx-3 align-items-start"},[_c('h3',[_vm._v("Worklogs")]),(false)?_c('div',{staticClass:"col-md-5"},[_c('Search',{attrs:{"fields":[]},on:{"searchChange":_vm.onSearchChange}})],1):_vm._e(),_c('router-link',{staticClass:"btn btn-white",attrs:{"to":"/crud/worklogs/new"}},[_vm._v(" New Worklog ")])],1),(_vm.isLoading && !_vm.pagination.results)?_c('b-skeleton-table',{attrs:{"animation":"wave","rows":20,"columns":5,"table-props":{ striped: true }}}):_vm._e(),(_vm.pagination && _vm.pagination.results)?_c('b-overlay',{staticClass:"table-responsive",attrs:{"show":!_vm.pagination.results || _vm.isLoading,"id":"entities"}},[_c('table',{staticClass:"table table-striped",attrs:{"aria-describedby":"page-heading"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-capitalize"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v("Id")]),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('span',{class:_vm.pagination.sort === 'id,asc'
                      ? 'text-postman'
                      : 'text-dark',on:{"click":function($event){return _vm.onPaginationChange({ sort: 'id,asc' })}}},[_c('i',{staticClass:"fas fa-angle-up"})]),_c('span',{class:_vm.pagination.sort === 'id,desc'
                      ? 'text-postman'
                      : 'text-dark',on:{"click":function($event){return _vm.onPaginationChange({ sort: 'id,desc' })}}},[_c('i',{staticClass:"fas fa-angle-down"})])])])]),_c('th',{staticClass:"text-capitalize"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v("assignee")]),_c('div',{staticClass:"ml-2 d-flex flex-column"},[_c('span',{class:_vm.pagination.sort === 'assignee,asc'
                      ? 'text-postman'
                      : 'text-dark',on:{"click":function($event){return _vm.onPaginationChange({ sort: 'assignee,asc' })}}},[_c('i',{staticClass:"fas fa-angle-up"})]),_c('span',{class:_vm.pagination.sort === 'assignee,desc'
                      ? 'text-postman'
                      : 'text-dark',on:{"click":function($event){return _vm.onPaginationChange({ sort: 'assignee,desc' })}}},[_c('i',{staticClass:"fas fa-angle-down"})])])])]),_c('th',{staticClass:"text-capitalize"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v("backlog_item_summary")])])]),_c('th',{staticClass:"text-capitalize"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_vm._v("spent_time_in_hours")]),_c('div',{staticClass:"ml-2 d-flex flex-column"})])])])]),_c('tbody',_vm._l((this.pagination.results),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.assignee))]),_c('td',[_vm._v(_vm._s(item.backlog_item_summary))]),_c('td',[_vm._v(_vm._s(item.spent_time_in_hours))]),_c('td',[_c('i',{staticClass:"fa-solid fa-pen-to-square",staticStyle:{"color":"orange"},on:{"click":function($event){return _vm.onEdit(item.id)}}})]),_c('td',[_c('i',{staticClass:"fa-solid fa-delete-left",on:{"click":function($event){return _vm.onDelete(item.id)}}})])])}),0)])]):_vm._e(),_c('Pagination',{attrs:{"size":_vm.pagination.size,"page":_vm.pagination.page,"total":_vm.pagination.total},on:{"paginationChange":_vm.onPaginationChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }