<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Product Backlog</h3>
    </div>
    <div v-for="sprint in sprints" :key="sprint.id">
      <h5>{{sprint.name}}</h5>
      <div v-if="!isLoading">
        <SprintBacklog :items="sprint.items" :activeSprint="activeSprint"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import SprintBacklog from './SprintBacklog.vue'

export default {
  name: 'ProdcutBacklog',

  components: {
    SprintBacklog,
  },

  data() {
    return {
      isLoading: true,
      sprints: [],
      activeSprint: null,
    }
  },

  methods: {
    ...mapGetters(['request']),

    async getSprints() {
      const url = `/api/v4/sprints`
      const params ={
        sort: 'id,desc'
      }
      await this.request().get(url, {params}).then(async (res) => {
        this.sprints = res.data.results
        const indexOfActiveSprint = res.data.results.findIndex(sprint => {
          return sprint.status = "ACTIVE"
        })
        this.activeSprint = res.data.results[indexOfActiveSprint]
        await this.refreshTableData()
      })
    },

    async refreshTableData() {
      for (const sprint of this.sprints) {
        const url = `/api/v4/sprints/${sprint.id}/backlog-items`
        await this.request().get(url).then(res => {
          sprint.items = res.data.filter(data => {
            return data.status != "DONE"
          })
        })
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    await this.getSprints()
  }
}
</script>