<template>
  <div>
    <div class="container">
      <h2>Server Version</h2>
      <p>AWS & GCP Of BanqUp</p>
      <table class="table">
        <thead>
          <tr>
            <th>Version</th>
            <th>Server</th>
            <th>Title</th>
            <th>Server log</th>
            <th>API Usage</th>
          </tr>
        </thead>
        <tbody v-for="item in dataAWSOfBanqUp" :key="item.id">
        <tr>
          <td>{{ item.version }}</td>
          <td>
            <a :href="item.text" target="_blank">{{ item.text }}</a>
          </td>
          <td>{{ item.value }}</td>
          <td>
            <a :href="item.splunk" target="_blank">Log</a>
          </td>
          <td>
            <a :href="item.api_usage" target="_blank">Link</a>
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <!-- <p>GCP Of BanqUp</p>
      <table class="table">
        <thead>
          <tr>
            <th>Version</th>
            <th>Server</th>
            <th>Title</th>
          </tr>
        </thead>
        <tbody v-for="item in dataGCPOfBanqUp" :key="item.id">
          <tr>
            <td>{{ item.version }}</td>
            <td>
              <a :href="item.text" target="_blank">{{ item.text }}</a>
            </td>
            <td>{{ item.value }}</td>
          </tr>
        </tbody>
      </table> -->
      <br />
      <p>Delivery Engine</p>
      <table class="table">
        <thead>
          <tr>
            <th>Version</th>
            <th>Server</th>
            <th>Title</th>
            <th>Server log</th>
          </tr>
        </thead>
        <tbody v-for="item in dataDeliveryEngine" :key="item.id">
          <tr>
            <td>{{ item.version }}</td>
            <td>
              <a :href="item.text" target="_blank">{{ item.text }}</a>
            </td>
            <td>{{ item.value }}</td>
            <td>
              <a :href="item.splunk" target="_blank">Log</a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />

      <p>AWS & GCP Of WebHook</p>
      <table class="table">
        <thead>
          <tr>
            <th>Version</th>
            <th>Server</th>
            <th>Title</th>
            <th>Server log</th>
            <th>API Usage</th>
          </tr>
        </thead>
        <tbody v-for="item in dataAWSOfWedHook" :key="item.id">
        <tr>
          <td>{{ item.version }}</td>
          <td>
            <a :href="item.text" target="_blank">{{ item.text }}</a>
          </td>
          <td>{{ item.value }}</td>
          <td>
            <a :href="item.splunk" target="_blank">Log</a>
          </td>
          <td>
            <a :href="item.api_usage" target="_blank">Link</a>
          </td>
        </tr>
        </tbody>
      </table>
      <br />
      <p>Payplan</p>
      <table class="table">
        <thead>
          <tr>
            <th>Version</th>
            <th>Server</th>
            <th>Title</th>
            <th>Server log</th>
          </tr>
        </thead>
        <tbody v-for="item in dataGCPOfPayplan" :key="item.id">
          <tr>
            <td>{{ item.version }}</td>
            <td>
              <a :href="item.text" target="_blank">{{ item.text }}</a>
            </td>
            <td>{{ item.value }}</td>
            <td>
              <a :href="item.splunk" target="_blank">Log</a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dataOBSUATECMA: [],
      dataAWSOfBanqUp: [],
      dataGCPOfBanqUp: [],
      dataAWSOfWedHook: [],
      dataGCPOfWedHook: [],
      dataDeliveryEngine: [],
      dataGCPOfPayplan:[],
      linksOBSUATECMA:[
        {
          evn: "UAT",
          url: "https://v4-api.uat.unifiedpost.fr",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22community_ecma_uat%22%20AND%20source%3D%22*-com-btb-ext-apis*%22&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-30m%40m&latest=now&sid=1661309986.1266229",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22community_ecma_uat%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
        {
          evn: "PROD",
          url: "https://v4-api.unifiedpost.fr",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/account/login?return_to=%2Fen-US%2Fapp%2Fsearch%2Fsearch%3Fq%3Dsearch%2520index%253D%2522community_ecma_prod%2522%2520AND%2520source%253D%2522%252A-com-btb-ext-apis%252A%2522%26display.page.search.mode%3Dsmart%26dispatch.sample_ratio%3D1%26earliest%3D-24h%2540h%26latest%3Dnow",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22community_ecma_prod%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684295157.2218198",
        },
      ],
      linksAWSOfBanqUp: [
        {
          evn: "TEST",
          url: "https://community-btb-external-apis-test-1-2.nxt.uat.unifiedpost.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_test-community-btb-external-apis%2F*&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=events&sid=1661390893.1316277",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_test-community-btb-external-apis%2F*%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
        {
          evn: "DEV",
          url: "https://community-btb-external-apis-dev-1-2.nxt.uat.unifiedpost.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_dev-community-btb-external-apis%2F%2A",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_dev-community-btb-external-apis%2F*%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
        {
          evn: "UAT",
          url: "https://community-btb-external-apis-uat-1-2.nxt.uat.unifiedpost.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_uat-community-btb-external-apis%2F*&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=events&sid=1661391092.1316358",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_uat-community-btb-external-apis%2F*%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
        {
          evn: "PROD",
          url: "https://community-btb-external-apis.nxt.unifiedpost.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_prod%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fcommunity-btb-external-apis%2F*&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=events&sid=1661391211.1316425",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_prod%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fcommunity-btb-external-apis%2F*%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
      ],
      linksGCPOfBanqUp: [
        {
          evn: "DEV",
          url: "https://v4-api.dev.platform.banqup.be",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_dev%22%20AND%20source%3D%22*-com-btb-ext-apis*%22&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&sid=1661309336.1265981",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_dev%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684295265.2218298\n",
        },
        {
          evn: "UAT",
          url: "https://v4-api.uat.platform.eu.banqup.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_uat%22%20AND%20source%3D%22*-com-btb-ext-apis*%22&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_uat%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684295265.2218298\n",
        },
        {
          evn: "PROD",
          url: "https://v4-api.platform.eu.banqup.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_prod%22%20AND%20source%3D%22*-com-btb-ext-apis*%22&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&sid=1661309355.1265991",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_prod%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics",
        },
        {
          evn: "TEST",
          url: "https://v4-api.test.platform.eu.banqup.com",
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_test%22%20AND%20source%3D%22*-com-btb-ext-apis*%22&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&sid=1661500082.1369864",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22banqup_eu_test%22%20AND%20source%3D%22*-com-btb-ext-apis*%22%20request_url%3D%22%2Fv4%2F*%22%20OR%20request_url%3D%22%2Fversion%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684295265.2218298\n",
        },
      ],
      linksAWSOfWedHook: [
        {
          evn: "PROD",
          url: `https://webhook-management.nxt.unifiedpost.com`,
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_prod%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fwebhook-management%2F*",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_prod%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fwebhook-management%2F*%20request_url%3D%22%2F*%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684808268.2720284",
        },
        {
          evn: "UAT",
          url: `https://webhook-management-uat-1-2.nxt.uat.unifiedpost.com`,
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_uat-webhook-management%2F*",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_uat-webhook-management%2F*%20request_url%3D%22%2F*%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684808268.2720284",
        },
        {
          evn: "TEST",
          url: `https://webhook-management-test-1-2.nxt.uat.unifiedpost.com`,
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_test-webhook-management%2F*",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_test-webhook-management%2F*%20request_url%3D%22%2F*%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684808268.2720284",
        },
        {
          evn: "DEV",
          url: `https://webhook-management-dev-1-2.nxt.uat.unifiedpost.com`,
          splunk:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_dev-webhook-management%2F*",
          api_usage:
              "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_dev-webhook-management%2F*%20request_url%3D%22%2F*%22%20request_method!%3DHEAD%20%7C%20stats%20avg(response_time)%20as%20avg_resp_time%20count%20by%20user_principal%2Crequest_method%2C%20request_url%2C%20http_code%20%7C%20sort%20-http_code&display.page.search.mode=smart&dispatch.sample_ratio=1&earliest=-24h%40h&latest=now&display.general.type=statistics&sid=1684808268.2720284",
        },
      ],

      linksGCPOfWedHook: [
        {
          evn: "DEV",
          url: "https://webhook-mgmt.dev.platform.eu.unifiedpost.com",
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-webhookmgmt-dev%22;timeRange=PT30M?project=pj-clusters-dev-be",
          api_usage:
              "https://console.cloud.google.com/monitoring/metrics-explorer;duration=PT1H?referrer=search&project=pj-clusters-dev-be&pageState=%7B%22xyChart%22:%7B%22constantLines%22:%5B%5D,%22dataSets%22:%5B%7B%22plotType%22:%22LINE%22,%22targetAxis%22:%22Y1%22,%22timeSeriesFilter%22:%7B%22aggregations%22:%5B%7B%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22groupByFields%22:%5B%5D,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%5D,%22apiSource%22:%22DEFAULT_CLOUD%22,%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22filter%22:%22metric.type%3D%5C%22monitoring.googleapis.com%2Fuptime_check%2Frequest_latency%5C%22%20resource.type%3D%5C%22uptime_url%5C%22%20resource.label.%5C%22host%5C%22%3D%5C%22webhook-mgmt.dev.platform.eu.unifiedpost.com%5C%22%22,%22groupByFields%22:%5B%5D,%22minAlignmentPeriod%22:%2260s%22,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%7D%5D,%22options%22:%7B%22mode%22:%22COLOR%22%7D,%22y1Axis%22:%7B%22label%22:%22%22,%22scale%22:%22LINEAR%22%7D%7D%7D",
        },
        {
          evn: "UAT",
          url: "https://webhook-mgmt.uat.platform.eu.unifiedpost.com",
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-webhookmgmt-uat%22;timeRange=PT30M?project=pj-clusters-uat-be",
          api_usage:
              "https://console.cloud.google.com/monitoring/metrics-explorer;duration=PT1H?referrer=search&project=pj-clusters-uat-be&pageState=%7B%22xyChart%22:%7B%22constantLines%22:%5B%5D,%22dataSets%22:%5B%7B%22plotType%22:%22LINE%22,%22targetAxis%22:%22Y1%22,%22timeSeriesFilter%22:%7B%22aggregations%22:%5B%7B%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22groupByFields%22:%5B%5D,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%5D,%22apiSource%22:%22DEFAULT_CLOUD%22,%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22filter%22:%22metric.type%3D%5C%22monitoring.googleapis.com%2Fuptime_check%2Frequest_latency%5C%22%20resource.type%3D%5C%22uptime_url%5C%22%20resource.label.%5C%22host%5C%22%3D%5C%22webhook-mgmt.uat.platform.eu.unifiedpost.com%5C%22%22,%22groupByFields%22:%5B%5D,%22minAlignmentPeriod%22:%2260s%22,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%7D%5D,%22options%22:%7B%22mode%22:%22COLOR%22%7D,%22y1Axis%22:%7B%22label%22:%22%22,%22scale%22:%22LINEAR%22%7D%7D%7D",
        },
        {
          evn: "PROD",
          url: "https://webhook-mgmt.platform.eu.unifiedpost.com",
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-webhookmgmt-prod%22;timeRange=PT30M?project=pj-clusters-prod-be",
          api_usage:
              "https://console.cloud.google.com/monitoring/metrics-explorer;duration=PT1H?referrer=search&project=pj-clusters-prod-be&pageState=%7B%22xyChart%22:%7B%22constantLines%22:%5B%5D,%22dataSets%22:%5B%7B%22plotType%22:%22LINE%22,%22targetAxis%22:%22Y1%22,%22timeSeriesFilter%22:%7B%22aggregations%22:%5B%7B%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22groupByFields%22:%5B%5D,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%5D,%22apiSource%22:%22DEFAULT_CLOUD%22,%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22filter%22:%22metric.type%3D%5C%22monitoring.googleapis.com%2Fuptime_check%2Frequest_latency%5C%22%20resource.type%3D%5C%22uptime_url%5C%22%20resource.label.%5C%22host%5C%22%3D%5C%22webhook-mgmt.platform.eu.unifiedpost.com%5C%22%22,%22groupByFields%22:%5B%5D,%22minAlignmentPeriod%22:%2260s%22,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%7D%5D,%22options%22:%7B%22mode%22:%22COLOR%22%7D,%22y1Axis%22:%7B%22label%22:%22%22,%22scale%22:%22LINEAR%22%7D%7D%7D",
        },
        {
          evn: "TEST",
          url: "https://webhook-mgmt.test.platform.eu.unifiedpost.com",
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-webhookmgmt-test%22;timeRange=PT30M?project=pj-clusters-test-be",
          api_usage:
              "https://console.cloud.google.com/monitoring/metrics-explorer;duration=PT1H?referrer=search&project=pj-clusters-test-be&pageState=%7B%22xyChart%22:%7B%22constantLines%22:%5B%5D,%22dataSets%22:%5B%7B%22plotType%22:%22LINE%22,%22targetAxis%22:%22Y1%22,%22timeSeriesFilter%22:%7B%22aggregations%22:%5B%7B%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22groupByFields%22:%5B%5D,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%5D,%22apiSource%22:%22DEFAULT_CLOUD%22,%22crossSeriesReducer%22:%22REDUCE_MAX%22,%22filter%22:%22metric.type%3D%5C%22monitoring.googleapis.com%2Fuptime_check%2Frequest_latency%5C%22%20resource.type%3D%5C%22uptime_url%5C%22%20resource.label.%5C%22host%5C%22%3D%5C%22webhook-mgmt.test.platform.eu.unifiedpost.com%5C%22%22,%22groupByFields%22:%5B%5D,%22minAlignmentPeriod%22:%2260s%22,%22perSeriesAligner%22:%22ALIGN_MEAN%22%7D%7D%5D,%22options%22:%7B%22mode%22:%22COLOR%22%7D,%22y1Axis%22:%7B%22label%22:%22%22,%22scale%22:%22LINEAR%22%7D%7D%7D",
        },
      ],
    
      linksDeliveryEngine: [
        {
          evn: "PROD",
          url: `https://webhook-delivery-engine.nxt.unifiedpost.com`,
          splunk:
            "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_prod%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fwebhook-delivery-engine%2F*",
        },
        {
          evn: "UAT",
          url: `https://webhook-delivery-engine-uat-1-2.nxt.uat.unifiedpost.com`,
          splunk:
            "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_uat-webhook_delivery_engine%2F*",
        },
        {
          evn: "TEST",
          url: `https://webhook-delivery-engine-test-1-2.nxt.uat.unifiedpost.com`,
          splunk:
            "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_test-webhook_delivery_engine%2F*",
        },
        {
          evn: "DEV",
          url: `https://webhook-delivery-engine-dev-1-2.nxt.uat.unifiedpost.com`,
          splunk:
            "https://unifiedpostgcp.splunkcloud.com/en-US/app/search/search?q=search%20index%3D%22nxt_uat%22%20AND%20source%3D%2Fvar%2Flog%2Fupnxt%2Fapps_services_dev-webhook_delivery_engine%2F*",
        },
      ],
      linksPayplan: [
        {
          evn: "DEV",
          url: `https://payment-planning.dev.unifiedpost-payments.com`,
          splunk:
            "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.cluster_name%3D%22gke-shared-cluster-be-dev%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-payplan-dev%22%0Aresource.labels.container_name%3D%22payplan-worker%22%20OR%20resource.labels.container_name%3D%22payplan-api%22;duration=PT1H?referrer=search&project=pj-bu-payplan-dev-be",
        },
        {
          evn: "TEST",
          url: `https://payment-planning.test.unifiedpost-payments.com`,
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0D%0Aresource.labels.cluster_name%3D%22gke-shared-cluster-be-test%22%0D%0Aresource.labels.namespace_name%3D%22ns-gcpbe-payplan-test%22;cursorTimestamp=2024-03-06T07:51:27.163372629Z?project=pj-bu-payplan-test-be",
        },
        {
          evn: "UAT",
          url: `https://payment-planning.uat.unifiedpost-payments.com`,
          splunk:
              "https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.cluster_name%3D%22gke-shared-cluster-be-uat%22%0Aresource.labels.namespace_name%3D%22ns-gcpbe-payplan-uat%22;duration=P1D?project=pj-bu-payplan-uat-be",
        },
        {
          evn: "PROD",
          url: `https://payment-planning.unifiedpost-payments.com`,
          splunk:
              ""
        }
      ],
    };
  },

  methods: {
    getVersion() {
      for (const item of this.linksAWSOfBanqUp) {
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataAWSOfBanqUp.push({
              version: res.data,
              text: item.url,
              value: "AWS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            });
          })
          .catch(() =>
            this.dataAWSOfBanqUp.push({
              version: "Failed",
              text: item.url,
              value: "AWS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            })
          );
      }
       for (const item of this.linksGCPOfBanqUp) {
        const title = 'GCP';
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataAWSOfBanqUp.push({
              version: res.data,
              text: item.url,
              value: title + "_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            });
          })
          .catch(() =>
            this.dataAWSOfBanqUp.push({
              version: "Failed",
              text: item.url,
              value: title + "_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            })
          );
      }

      for (const item of this.linksDeliveryEngine) {
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataDeliveryEngine.push({
              version: res.data,
              text: item.url,
              value: "AWS_" +item.evn,
              splunk: item.splunk,
            });
          })
          .catch(() =>
            this.dataDeliveryEngine.push({
              version: "Failed",
              text: item.url,
              value: "AWS_" +item.evn,
              splunk: item.splunk,
            })
          );
      }


      for (const item of this.linksAWSOfWedHook) {
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataAWSOfWedHook.push({
              version: res.data,
              text: item.url,
              value: "AWS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            });
          })
          .catch(() =>
            this.dataAWSOfWedHook.push({
              version: "Failed",
              text: item.url,
              value: "AWS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            })
          );
      }
      //
       for (const item of this.linksGCPOfWedHook) {
        const title = 'GCP';
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataAWSOfWedHook.push({
              version: res.data,
              text: item.url,
              value: title + "_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            });
          })
          .catch(() =>
            this.dataAWSOfWedHook.push({
              version: "Failed",
              text: item.url,
              value: title + "_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            })
          );
      }

      for (const item of this.linksOBSUATECMA) {
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataAWSOfBanqUp.push({
              version: res.data,
              text: item.url,
              value: "OBS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            });
          })
          .catch(() =>
            this.dataAWSOfBanqUp.push({
              version: "Failed",
              text: item.url,
              value: "OBS_" + item.evn,
              splunk: item.splunk,
              api_usage: item.api_usage,
            })
          );
      }

      for (const item of this.linksPayplan) {
        axios
          .get(item.url + "/version")
          .then((res) => {
            this.dataGCPOfPayplan.push({
              version: res.data,
              text: item.url,
              value: "GCP_"+item.evn,
              splunk: item.splunk,
            });
          })
          .catch(() =>
            this.dataGCPOfPayplan.push({
              version: "Failed",
              text: item.url,
              value: "GCP_"+item.evn,
              splunk: item.splunk,
            })
          );
      }
    },
  },
  async mounted() {
    await this.getVersion();
  },
};
</script>
