<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Create Sprint Commitments</h3>
    </div>
    <form v-on:submit.prevent="onSubmit">
      <div class="row">
        <div class="col-sm-2">
          <div class="form-group">
            <label for="user_id" class="form-control-label"
              >User Id<span class="text-danger">*</span></label
            >
            <input
              required
              type="number"
              class="form-control"
              name="user_id"
              id="user_id"
              placeholder="User Id"
              v-model="user_id"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="sprint_id" class="form-control-label"
              >Sprint Id<span class="text-danger">*</span></label
            >
            <input
              required
              type="number"
              class="form-control"
              name="sprint_id"
              id="sprint_id"
              placeholder="Sprint Id"
              v-model="sprint_id"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="committed_delivery_workload" class="form-control-label"
              >Commitment Delivery Workload</label
            >
            <input
              type="number"
              step="0.5"
              class="form-control"
              name="committed_delivery_workload"
              id="committed_delivery_workload"
              placeholder="Delivery"
              v-model="committed_delivery_workload"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="committed_build_up_workload" class="form-control-label"
              >Commitment Build Up Workload</label
            >
            <input
              type="number"
              step="0.5"
              class="form-control"
              name="committed_build_up_workload"
              id="committed_build_up_workload"
              placeholder="Build Up"
              v-model="committed_build_up_workload"
            />
          </div>
        </div>
      </div>
      <button :disabled="isActive" type="submit" id="save" class="btn-success">
        Create Sprint Commitment
      </button>
    </form>
    <button
      class="btn-danger my-2"
      @click="$router.push('/crud/sprint-commitments')"
    >
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import router from "../router";
export default {
  name: "SprintCommitmentNew",

  data() {
    return {
      isActive: false,
      user_id: null,
      sprint_id: null,
      committed_delivery_workload: null,
      committed_build_up_workload: null,
      activeSprint: [],
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      this.isActive = true;
      const url = `api/v4/sprint-commitments`;
      this.request()
        .post(url, {
          user_id: this.user_id,
          sprint_id: this.sprint_id,
          committed_delivery_workload: this.committed_delivery_workload,
          committed_build_up_workload: this.committed_build_up_workload,
        })
        .finally(() => {
          router.push("/crud/sprint-commitments");
        });
    },

    async getUsers() {
      const user_url = `api/v4/users`;
      await this.request()
        .get(user_url)
        .then((res) => {
          this.user_id = res.data.find((e) => {
            return localStorage.getItem("username") == e.email.split("@")[0];
          }).id;
        });
    },

    async getActiveSprint() {
      const url = `api/v4/sprints/active`;
      await this.request()
        .get(url)
        .then((res) => {
          this.sprint_id = res.data.id;
        });
    },
  },

  mounted() {
    this.getUsers()
    this.getActiveSprint()
  }
};
</script>
