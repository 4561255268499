import router from '../../router';
import axios from "axios";
import store from "../index";

const state = {
  token: localStorage.getItem('access_token'),
  environment: localStorage.getItem('environment') || 'PROD',
  redirect: null,
  googleRedirectUrl: process.env.VUE_APP_GOOGLE_REDIRECT_URL,
  googleClientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  googleClientSecret: process.env.VUE_APP_GOOGLE_CLIENT_SECRET,
};

// const BASE_URLS = {
//   'LOCAL': 'http://localhost:8092',
//   'PHONE': 'http://10.0.2.2:8092',
//   'PROD': 'https://snazzy-capi.betters.today',
// };


const getters = {
  isLoggedIn: (state) => {
    return !!state.token;
  },
  request: (state) => {
    const request = axios.create({
      baseURL: process.env.VUE_APP_SNAZZY_BASE_URL,
      timeout: 60000,
      headers: {
        'Authorization': `Bearer ${state.token}`
      }
    });

    request.interceptors.request.use(function (config) {
      config.timeData = {startTime: new Date()};
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    request.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      const response = error.response;

      const reponseDebugMessage = `${response.config.url} responded with status ${response.status}`;
      store.dispatch('toastError', reponseDebugMessage);

      if ([401, 403].indexOf(response.status) >= 0) {
        state.redirect = window.location.pathname
        localStorage.clear();
        router.push('/');
      }

      return Promise.reject(error);
    });

    return request;
  },
  baseUrl: (state) => (environment) => {
    console.log(environment)
    return state.baseURL
  },
  googleGetIdTokenUrl: (state) => (code, environment) => {
    console.log(environment)
    return "https://oauth2.googleapis.com/token?code=" + code + `&client_id=${state.googleClientId}&client_secret=${state.googleClientSecret}&redirect_uri=${state.googleRedirectUrl}&grant_type=authorization_code`
  },
  googleRedirectUrl: (state) => (environment) => {
    console.log(environment)
    return `https://accounts.google.com/o/oauth2/v2/auth?scope=email&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${state.baseURL}&client_id=${state.googleClientId}`
  },
  googleGetIdTokenFromRefreshTokenUrl: (refresh_token) => {
    return `https://oauth2.googleapis.com/token?client_id=${state.googleClientId}&client_secret=${state.googleClientSecret}&grant_type=refresh_token&refresh_token=` + refresh_token
  }
};

const actions = {
  login: ({commit}) => {
    commit('setToken', localStorage.getItem('access_token'));
  },
  logout: ({commit}) => {
    commit('setToken', null);
    localStorage.clear();
    console.log('Successfully signed-out!');
    router.push('/');
  },
  finalizeLogin() {
    if (state.redirect != null && state.redirect !== "" && state.redirect !== "/") {
      router.push(state.redirect)
    } else {
      router.push('/meeting-minutes')
    }
  }
}

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
}
