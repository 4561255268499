<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Edit Sprint</h3>
    </div>

    <div class="row">
      <div class="col-8 mx-auto">
        <form v-on:submit.prevent="onSubmit">
          <div>
            <div class="form-group" v-show="false">
              <label for="sprint_id" class="form-control-label"
                >Sprint Id <span class="text-danger">*</span></label
              >
              <input
                required
                type="text"
                class="form-control"
                name="sprint_id"
                id="sprint_id"
                placeholder="sprint_id"
                v-model="data.id"
              />
            </div>

            <div class="form-group">
              <label for="name" class="form-control-label">Sprint name</label>
              <input
                type="text"
                class="form-control"
                name="name"
                id="name"
                placeholder="name"
                v-model="name"
              />
            </div>
            <label for="start_date">Start date</label>
            <b-form-datepicker
              name="start_date"
              id="start_date"
              v-model="start_date"
            >
            </b-form-datepicker>

            <label for="end_date">End date</label>
            <b-form-datepicker name="end_date" id="end_date" v-model="end_date">
            </b-form-datepicker>

            <div class="form-group">
              <label for="description" class="form-control-label"
                >description</label
              >
              <input
                type="text"
                class="form-control"
                name="description"
                id="description"
                placeholder="description"
                v-model="description"
              />
            </div>

            <div class="form-group">
              <label for="sprint_number" class="form-control-label"
                >sprint_number</label
              >
              <input
                type="text"
                class="form-control"
                name="sprint_number"
                id="sprint_number"
                placeholder="sprint_number"
                v-model="sprint_number"
              />
            </div>

            <div class="form-group">
              <label for="status" class="form-control-label">Status</label>
              <b-form-select
                v-model="status"
                :options="['DRAFT', 'ACTIVE', 'CLOSED']"
                class="mb-3"
              >
              </b-form-select>
            </div>
          </div>

          <div>
            <button type="submit" id="save" class="btn-success">Save</button>
            <button class="btn-danger" @click="$router.go(-1)">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import router from "../router";
import { mapGetters } from "vuex";

export default {
  components: {},

  data() {
    return {
      data: null,
      id: null,
      name: null,
      start_date: null,
      end_date: null,
      description: null,
      sprint_number: null,
      status: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    getData() {
      const url = `api/v4/sprints/${this.$route.params.id}`;

      this.request()
        .get(url)
        .then((res) => {
          (this.data = res.data),
            (this.sprint_number = res.data.sprint_number),
            (this.id = res.data.id),
            (this.name = res.data.name),
            (this.start_date = res.data.start_date),
            (this.end_date = res.data.end_date),
            (this.description = res.data.description),
            (this.status = res.data.status);
        });
    },

    onSubmit() {
      const url = `api/v4/sprints/${this.id}`;

      this.request()
        .put(url, {
          id: this.id,
          name: this.name,
          start_date: this.start_date,
          end_date: this.end_date,
          description: this.description,
          sprint_number: this.sprint_number,
          status: this.status,
        })
        .then(() => {
          router.push("/crud/sprints");
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
