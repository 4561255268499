<template>
  <div>
    <div class="col-lg-6 col-10 mx-auto text-center">
      <h2>
        Link account
      </h2>
      <b-form v-on:submit.prevent="onLinkAccount">
      <label>Email: </label>
        <b-form-input 
          id="email-input"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required>
        </b-form-input>
      <label>Password: </label>
        <b-form-input 
          id="password-input"
          v-model="form.password"
          type="password"
          placeholder="Enter password"
          required>
        </b-form-input>
        <b-button class="m-2" type="submit" variant="primary">Link account</b-button>
      </b-form>
    </div>
  </div>
  
</template>

<script lang="ts">
import { getAuth, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import router from "../../router";

export default {
  name: 'LinkAccount',

  data() {
    return {
      auth: getAuth(),
      form: {
        email: '',
        passwork: ''
      }
    }
  },

  methods: {
    onLinkAccount() {
      const credential = EmailAuthProvider.credential(this.form.email, this.form.password);
      linkWithCredential(this.auth.currentUser, credential)
        .then((usercred) => {
          console.log(usercred)
          alert("Account linking success");
          router.push('/meeting-minutes')
        }).catch((error) => {
          console.error(error.message)
          alert("Can't link account: " + error.message);
        });
    },
  },

  mounted() {
    if(!this.auth.currentUser) {
      alert("You have not signed in yet. Please login with google first!")
      router.push('/')
    }
  }
  
}
</script>