import { mapGetters } from "vuex";
import router from "../router";

export default {
  name: "TeamDate",
  components: {},

  data() {
    return {
      sprint: null,
      results: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    getActiveSprint() {
      const url = `api/v4/sprints/active`;

      this.request()
        .get(url)
        .then((res) => {
          this.sprint = res.data;
          const end_date = new Date(this.sprint.end_date);
          this.sprint.end_date = end_date.setDate(end_date.getDate() + 1);
          const start_date = new Date(this.sprint.start_date);
          this.sprint.start_date = start_date.setDate(start_date.getDate() - 1);
        });
    },

    refreshTableData() {
      window.scroll(0, 0);
      const url = `api/v4/teamdate`;
      const params = {
        sort: "date,desc",
        size: 20,
      };
      this.request()
        .get(url, {
          params,
        })
        .then((res) => {
          this.results = res.data.results;
        });
    },

    onEdit(id) {
      router.push("/crud/teamdate/" + id + "/edit");
    },
  },

  mounted() {
    this.getActiveSprint();
    this.refreshTableData();
  },
};
