<template>
  <div>
    <div class="d-flex justify-content-between mb-4 mx-3 align-items-start">
      <h3>Create Sprint Retrospective</h3>
    </div>

    <form v-on:submit.prevent="onSubmit">
      <div class="col-sm-2">
        <div class="form-group">
          <label for="sprint_id" class="form-control-label">Sprint</label>
          <p>{{ sprint.name }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="process" class="form-control-label">Process</label>
            <textarea
              type="text"
              class="form-control"
              name="process"
              id="process"
              placeholder="Process"
              rows="10"
              v-model="process"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="tools" class="form-control-label">Tools</label>
            <textarea
              type="text"
              class="form-control"
              name="tools"
              id="tools"
              placeholder="Tools"
              rows="10"
              v-model="tools"
            />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label for="people" class="form-control-label">People</label>
            <textarea
              type="text"
              class="form-control"
              name="people"
              id="people"
              placeholder="People"
              rows="10"
              v-model="people"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="relationship" class="form-control-label"
              >Relationship</label
            >
            <textarea
              type="text"
              class="form-control"
              name="relationship"
              id="relationship"
              placeholder="Relationship"
              rows="10"
              v-model="relationship"
            />
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <label for="other" class="form-control-label">Other</label>
            <textarea
              type="text"
              class="form-control"
              name="other"
              id="other"
              placeholder="Other"
              rows="10"
              v-model="other"
            />
          </div>
        </div>
      </div>
      <button :disabled="isActive" type="submit" id="save" class="btn-success">
        Create Sprint Retrospective
      </button>
    </form>
    <button
      class="btn-danger my-2"
      @click="$router.push('/sprint-retrospectives')"
    >
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import router from "../router";
export default {
  name: "SprintRetrosNew",

  data() {
    return {
      sprint: null,
      isActive: false,
      assignee: localStorage.getItem("username"),
      sprint_id: null,
      process: null,
      tools: null,
      people: null,
      relationship: null,
      other: null,
      date: null,
    };
  },

  methods: {
    ...mapGetters(["request"]),

    onSubmit() {
      this.isActive = true;
      const url = "api/v4/sprint-retros";
      this.request()
        .post(url, {
          assignee: this.assignee,
          sprint_id: this.sprint_id,
          process: this.process,
          tools: this.tools,
          people: this.people,
          relationship: this.relationship,
          other: this.other,
          date: this.date,
        })
        .then(() => {
          router.push("/sprint-retrospectives");
        });
    },

    getActiveSprint() {
      const url = `api/v4/sprints/active`;
      this.request()
        .get(url)
        .then((res) => {
          this.sprint = res.data;
          this.sprint_id = res.data.id;
        });
    },
  },

  mounted() {
    this.getActiveSprint();
    const today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    this.date = yyyy + "-" + mm + "-" + dd;
  },
};
</script>
