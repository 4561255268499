import { mapGetters } from 'vuex';
import router from '../router';
import MemberAccountability from './MemberAccountability'
import TeamDate from './TeamDate'

export default {
  name: 'MeetingMinute',
  components: {
    MemberAccountability,
    TeamDate,
  },

  data() {
    return {
      isHidden: false,
      username: localStorage.getItem('username'),
      date: [],
      data: [],

      paginationReset: {
        total: 0,
        results: null,
        size: 20,
        page: 0,
        sort: 'created_at,desc'
      },
      pagination: {
        total: 0,
        results: null,
        size: 20,
        page: 0,
        sort: 'created_at,desc'
      },
    }
  },

  methods: {
    ...mapGetters(['request']),

    async refreshTableData() {
      window.scrollTo(0,0)
      const url = `api/v4/meeting-minutes`;
      for (var j in this.date) {
        const params = {
          page: this.pagination.page,
          size: this.pagination.size,
          sort: this.pagination.sort,
          date: this.date[j]
        }
        await this.request().get(url, {
          params
        }).then(res => {
          if (res.data.results.length != 0){
            this.data.push(res.data.results)
          }
        })
      }
    }, 

    editMeetingMinute(id) {
      router.push('/crud/meeting-minutes/'+id+'/edit')
    },
  },
  
  mounted() {
    const today = new Date()
    if (today.getDay() == 0) {
      today.setDate(today.getDate() - 1);
    }
    for(var i=1; i<6; i++) {
      today.setDate(today.getDate() - today.getDay() + i)
      if (today > new Date()) {
        break;
      }
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) {
          dd = '0' + dd;
      }
      if (mm < 10) {
          mm = '0' + mm;
      }
      this.date.push(yyyy + '-' + mm + '-' + dd);
    }
    this.date = this.date.reverse()
    this.refreshTableData()
  }
}